import img1 from "./imgs/1.png";
import img2 from "./imgs/2.png";
import styles from "../Facilities.module.scss";

const Page = () => {
  return (
    <table className={styles.images}>
      <tbody>
        <tr>
          <td>
            <img src={img1} />
            <p>입관실</p>
          </td>
          <td>
            <img src={img2} />
            <p>안치실</p>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default Page;
