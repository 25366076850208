import { useEffect, useState } from 'react';
import styles from './NaturalGround.module.scss';
import ground from './img/ground.png'
import method from './img/method.png'
import realflower from './img/realflower.png'
import nosmoking from './img/nosmoking.png'
import nopets from './img/nopets.png'
import noincense from './img/noincense.png'
import nofood from './img/nofood.png'


const Inscription :React.FC = () => {

    return(
        <main className={styles.container}>
            <section className={styles.description}>
                <div className={styles.callout}>
                    <div>자연장지 운영시간 <strong>(09:00~18:00)</strong> /</div>
                    <div>접수시간 <strong>(07:00 ~ 16:00)</strong></div>
                </div>
                <div className={styles.intro}>
                    <img className={styles.introImage} src={ground} width="460px" height="305px"/>
                    <div className={styles.introContent}>
                        <h3 className={styles.heading}>자연장지-수목장, 잔디장</h3>
                        <ul>
                            <li>수려한 주변산세와 아름다운 풍광이 어우러진 자연장지!</li>
                            <li>자연장지는 화장한 유골을 수목과 잔디에 모시는 자연친화적인 안장공간입니다.</li>
                        </ul>
                    </div>
                </div>
                
            </section>

            <section className={styles.content}>
                <h3 className={styles.subheading}>자연장지 유골안장 방식</h3>
                <div className={styles.smallContent}>
                <ul>
                    <li>구역 내 준비해 놓은 홀에 골분과 마사토를 섞어서 안장을 하고 잔디로 마감합니다.</li>
                    <li>안장 후 공동 표지석에 개인 명패를 부착합니다.</li>
                </ul>
                </div>
                <img className={styles.imgbox} src={method} />

                <h3 className={styles.subheading}>자연장지 참배 안내</h3>
                
                <section className={styles.table_container}>
                    <table>
                        <tbody>
                            <tr>
                                <td className={styles.infoImg}> <img  src={realflower} width="150px" height="108px"/> </td>
                                <td className={styles.infoImg}> <img src={nosmoking} width="150px" height="108px"/> </td>
                                <td className={styles.infoImg}> <img src={nopets} width="150px" height="108px"/> </td>
                                <td className={styles.infoImg}> <img src={noincense} width="150px" height="108px"/> </td>
                                <td className={styles.infoImg}> <img src={nofood} width="150px" height="108px"/> </td>
                            </tr>
                            <tr>
                                <td className={styles.regular}>헌화는 생화(生花)만 가능</td>
                                <td className={styles.regular}>흡연금지</td>
                                <td className={styles.regular}>반려동물 출입금지</td>
                                <td className={styles.regular}>분향금지<br/>(실내 제례실 이용)</td>
                                <td className={styles.regular}>음식물 반입금지<br/>(실내 제례실 이용)</td>
                            </tr>
                        </tbody>
                    </table>
                </section>

                <h3 className={styles.subheading}>사용대상</h3>
                <div className={styles.smallContent}>
                <ul>
                    <li>잔디장 : 제한없음 (누구나 사용가능)</li>
                    <li>수목장 : 사망당시 울산광역시 거주자(※관외 거주자 안치불가)</li>
                </ul>
                </div>

                <h3 className={styles.subheading}>사용기간</h3>
                <div className={styles.smallContent}>
                <ul>
                    <li>자연장지의 사용기간은 안장일부터 30년까지이며 연장되지 않습니다.</li>
                </ul>
                </div>

                <h3 className={styles.subheading}>안장절차</h3>
                <section className={styles.table_container}>
                    <div className={styles.elementBlock}>
                        <table>
                            <tbody>
                            <tr>
                                <th>1. 도착</th>
                                <td>유족은 구비서류를 완비하여 종합 안내데스크에 신청을 합니다.</td>
                            </tr>
                            </tbody>
                        </table>
                        </div>

                        <div className={styles.elementBlock}>
                        <table>
                            <tbody>
                            <tr>
                                <th>2. 서류확인</th>
                                <td>서류 확인 후, 추모의 집으로 이동합니다.</td>
                            </tr>
                            </tbody>
                        </table>
                        </div>

                        <div className={styles.elementBlock}>
                        <table>
                            <tbody>
                            <tr>
                                <th>3. 봉안</th>
                                <td>지정된 장소에 유골을 봉안합니다.</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </section>

                <h3 className={styles.subheading}>구비서류</h3>
                <section className={styles.table_container_gubi}>
                    <table className={styles.tableContainer}>
                        <thead>
                            <th>방문하여 작성할 서류</th>
                            <th>사용자 필수 지참 서류</th>
                            <th>울산하늘공원 확인 가능 서류</th>
                        </thead>
                        <tbody>
                            <tr>
                                <td>                
                                    <ul>
                                        <li>울산하늘공원 사용 허가 신청서</li>
                                        <li>유골인도신청서(유골인도 시)</li>
                                    </ul>
                                </td>
                                <td>                
                                    <ul>
                                        <li>화장신고증명서(타 시설 화장 시)</li>
                                        <li>말소자 등·초본(시설변경 시)</li>
                                        <li>타시설사용증명서(시설변경 시)</li>
                                    </ul>
                                </td>
                                <td>                
                                    <ul>
                                        <li>주민등록등본(고인)</li>
                                        <li>국가유공자증명</li>
                                        <li>국민기초생활수급증명</li>
                                    </ul>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </section>
                <p className={styles.noticRed}>시설사용 신청인은 신분증을 지참하셔야 합니다.</p>



                <h3 className={styles.subheading}>유의사항</h3>
                <ul>
                    <li>자연장지에는 봉안용기없이 유골만 안장하므로 한번 모셔진 유골은 반출할 수 없습니다.</li>
                    <li>수목장은 한 그루당 30구가 순서대로 개별 안장되며, 잔디장은 한 구역당 300~600구가 순서대로 개별 안장됩니다.</li>
                    <li>안장 후 구역별 공동표지석에 개인 명패를 부착하고 안장구역에는 잔디를 심기때문에 정확한 안장위치는 알 수 없습니다. (안장구역 확인은 가능)</li>
                    <li>참배시 안장구역 안으로 들어갈 수 없으며, 흡연 및 분향·반려동물 출입·음식물 반입은 금지됩니다.</li>
                    <li>헌화는 생화만 가능하며 각종 제례는 별도 마련된 실내 제례실을 이용하시면 됩니다.</li>
                </ul>

                <h3 className={styles.subheading}>시설 사용료</h3>
                <section className={styles.table_container_gubi}>
                    <table className={styles.tableContainer}>
                        <thead>
                            <th>구분</th>
                            <th>단위</th>
                            <th>관내주민</th>
                            <th>관외주민</th>
                        </thead>
                        <tbody>
                            <tr>
                                <td className={styles.firstTd}>잔디장</td>
                                <td>30년</td>
                                <td>300,000</td>
                                <td>1,000,000</td>
                            </tr>
                            <tr>
                                <td>수목장</td>
                                <td>30년</td>
                                <td>1,400,000</td>
                                <td><span className={styles.red}>사용불가</span></td>
                            </tr>
                        </tbody>
                    </table>
                </section>
                <p className={styles.noticeBlue}>관내주민이란 사망 당시에 주민등록이 울산광역시에 되어 있는 자를 말합니다.</p>
                <p className={styles.notice}>부부장 사용료는 관내‧관외 및 감면 요금을 각각 적용합니다.</p>


                <h3 className={styles.subheading}>사용료 면제(감면 적용 사용료)</h3>
                <ul className={styles.ul_last}>
                    <li>「국민기초생활보장법」에 따른 생계·의료급여 수급자</li>
                    <li>「국가보훈기본법」에 따른 희생/공헌자와 그 배우자</li>
                </ul>
                <section className={styles.table_container_last}>
                    <table className={styles.tableLastContainer}>
                        <thead>
                            <th>구분</th>
                            <th>단위</th>
                            <th>관내주민</th>
                            <th>양산주민</th>
                            <th>비고</th>

                        </thead>
                        <tbody>
                            <tr>
                                <td>잔디장</td>
                                <td>30년</td>
                                <td>150,000</td>
                                <td>500,000</td>
                                <td>감면대상자에 한함</td>
                            </tr>
                            <tr>
                                <td>수목장</td>
                                <td>30년</td>
                                <td>700,000</td>
                                <td><span className={styles.red}>사용불가</span></td>
                                <td>감면대상자에 한함</td>

                            </tr>
                        </tbody>
                    </table>
                </section>
            </section>

        </main>

        
    )
}

export default Inscription;