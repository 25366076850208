import styles from "./DeathbedBefore.module.scss";
import mobileImage from "./img/Frame 4552.svg";
import { useMediaQuery } from "../../../Introduction/Philosophy/useMediaQuery";

const DeathbedBefore = () => {
  const isMobile = useMediaQuery("(max-width: 1023px)");

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        울산하늘공원 장례식장은 빈소 및 장례 용품 등 고인의 임종 전 장례절차
        사전 상담은 가능하나, 빈소 예약은 할 수 없음을 알려 드립니다.
      </div>

      <div className={styles.flowchart}>
        <div className={styles.flowchartRow}>
          <div className={styles.flowchartItem}>
            <div className={styles.box}>울산하늘공원 장례식장 방문</div>
            <div className={styles.arrow} />
          </div>

          <div className={styles.flowchartItem}>
            <div className={styles.box}>장례 사무실</div>
            <div className={styles.arrow} />
          </div>

          <div className={styles.flowchartItem}>
            <div className={styles.box}>사전 상담 신청</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeathbedBefore;
