import styles from './MemorialHouse.module.scss';
import house from './imgs/image 107.png'
import { useMediaQuery } from "../../Introduction/Philosophy/useMediaQuery"; // 위에서 만든 훅을 가져옴

export default function MemorialHouse() {

    const isMobile = useMediaQuery("(max-width: 1023px)");

    return (
        <main className={styles.container}>
            {isMobile ?( <>
                <div className={styles.callout2}>추모의 집 운영시간<span>&nbsp;(09:00 ~ 18:00)</span>/<br/>&nbsp;&nbsp;접수시간<span>&nbsp;(07:00 ~ 16:00)</span></div>
                </> ):(<>
                    <div className={styles.callout2}>추모의 집 운영시간<span>&nbsp;(09:00 ~ 18:00)</span>&nbsp;/&nbsp;접수시간<span>&nbsp;(07:00 ~ 16:00)</span></div>
                </>)}
            <section className={styles.imageContainer}>
                <img className={styles.image} src={house} />
                <div className={styles.descriptionContainer}>
                    <div className={styles.description}>
                        <h3>&lt;추모의 집&gt;</h3>
                        <ul>
                            <li>화장된 유골을 봉안하기 위한 시설입니다.</li>
                            <li>양지바른 곳에 위치한 고인의 영원한 안식처 입니다.</li>
                            <li>개인과 부부단으로 구분되어 있으며 살아생전의 고인을 되새기며 추모할 수 있습니다.</li>
                        </ul>
                    </div>
                </div>
            </section>

            <section className={styles.usage}>
                <h3 className={styles.subheading2}>사용대상</h3>
                    <ul>
                        <li><span className={styles.highlight}>개인단:</span> <span className={styles.text}>사망당시 울산 시민만 사용가능</span></li>
                        <li><span className={styles.highlight}>부부단:</span> <span className={styles.text}>부부중 한명이 개인단 이용대상이 되면 부부단에 안치가능</span></li>
                    </ul>
            </section>

            <section className={styles.usagePeriod}>
                <h3 className={styles.subheading2}>사용기간</h3>
                <ul>
                    <li>추모의 집 사용기간은 15년으로 하며, 사용자가 연장허가를 신청하는 경우에는 1회 5년씩 총 3회까지 연장할 수 있습니다. <br></br>다만, 부부단의 경우 합장한 날부터 사용기간을 산정하며, 무연고자의 경우에는 사용기간을 10년 이내로 합니다.</li>
                    <li>추모의 집의 사용기간을 연장하려는 유족은 사용기간이 만료되기 3개월 전까지 연장허가를 신청하여야 합니다.</li>
                    <li>유골 반출시(봉안번호를 부여받았음에도 불구하고 봉안하지 않은 경우를 포함한다) 또는 사용기간이 만료되었을 시, 재봉안은 불가합니다.</li>
                </ul>
            </section>

            <section className={styles.cremation}>
                <h3 className={styles.subheading2}>안장절차</h3>
                <div className={styles.tableDiv}>
                    <table className={styles.procedureTable}>
                        <tr>
                            <td><span>1. 도착</span></td>
                            <td>유족은 구비서류를 완비하여 종합 안내데스크에 신청을 합니다.</td>
                        </tr>
                        <tr>
                            <td><span className={styles.spanTwo}>2. 서류확인</span></td>
                            <td>서류 확인 후, 추모의 집으로 이동합니다.</td>
                        </tr>
                        <tr>
                            <td><span>3. 봉안</span></td>
                            <td>지정된 장소에 유골을 봉안합니다.</td>
                        </tr>
                    </table>
                </div>
            </section>

            <section className={styles.document}>
                <h3 className={styles.subheading3}>구비서류</h3>
                <div className={styles.tableDiv}>
                    <table className={styles.documentTable}>
                        <thead>
                            <th>방문하여 작성할 서류</th>
                            <th>사용자 필수 지참 서류</th>
                            <th>울산하늘공원 확인 가능 서류</th>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <div className={styles.documentDiv1}>
                                        <li>울산하늘공원 사용 허가 신청서</li>
                                        <li>유골인도신청서</li>
                                    </div>
                                </td>
                                <td>
                                    <div className={styles.documentDiv2}>
                                        <li>화장신고증명서</li>
                                        <li>말  소자 등·초본(시설변경 시)</li>
                                        <li>타시설사용증명서(시설변경 시)</li>
                                    </div>
                                </td>
                                <td>
                                    <div className={styles.documentDiv3}>
                                        <li>주민등록등본(고인)</li>
                                        <li>국가유공자증명</li>
                                        <li>국민기초생활수급증명</li>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className={styles.textContainer}>※ 시설사용 신청인은 신분증을 지참하셔야 합니다.</div>
            </section>

            <section className={styles.caution}>
                <h3 className={styles.subheading3}>유의사항</h3>
                <ul>
                    <li>추모의 집 봉안은 화장 접수 시 신청가능하며 당일에만 가능합니다.</li>
                    <li>봉안번호를 부여받았음에도 불구하고 기타사유(봉안장소, 봉안위치, 타 시설봉안 등)로 안치를 하지 않을 경우, 하늘공원에 재안치는 불가합니다.</li>
                    <li>추모의 집에서 한번 반환된 유골은 추모의 집에 재안치는 불가합니다. (개인단에서 부부단으로 시설변경은 1회에 한하여 가능)</li>
                    <li>참배객이 많은 설, 추석 명절 당일에는 제물상 진설은 불가하며 분향 및 헌화만 가능합니다.</li>
                    <li>추모의 집 내부로 반려동물 출입은 금지이며 헌화는 생화만 가능합니다.</li>
                </ul>
            </section>

            <section className={styles.price}>
                <h3 className={styles.subheading3}>시설 사용료</h3>
                <div className={styles.tableDiv}>
                    <table>
                        <thead>
                            <tr>
                                <th>구분</th>
                                <th>내용</th>
                                <th>단위</th>
                                <th>관내주민</th>
                                <th>관외주민</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className={styles.first} rowSpan={2}>잔디장</td>
                                <td>최초사용료</td>
                                <td>1구당(15년)</td>
                                <td>330,000</td>
                                <td className={styles.unavailable}>이용불가</td>
                            </tr>
                            <tr>
                                <td>연장사용료</td>
                                <td>1구당(5년)</td>
                                <td>110,000</td>
                                <td className={styles.unavailable}>이용불가</td>
                            </tr>
                            <tr>
                                <td className={styles.first} rowSpan={2}>부부단</td>
                                <td>최초사용료</td>
                                <td>1구당(15년)</td>
                                <td>330,000</td>
                                <td>1,000,000</td>
                            </tr>
                            <tr>
                                <td>연장사용료</td>
                                <td>1구당(5년)</td>
                                <td>110,000</td>
                                <td>400,000</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className={styles.textContainer}>※ 관내주민이란 사망 당시에 주민등록이 울산광역시에 되어 있는 자를 말합니다.</div>
                <div className={styles.textContainer2}>※ 부부장 사용료는 관내‧관외 및 감면 요금을 각각 적용합니다.</div>
            </section>

            <section className={styles.priceExempt}>
                <h3 className={styles.subheading3}>사용료 면제(감면 적용 사용료)</h3>
                <ul>
                    <li>「국민기초생활보장법」에 따른 생계·의료급여 수급자</li>
                    <li>「국가보훈기본법」에 따른 희생/공헌자와 그 배우자</li>
                    <li>시 관내에서 사망한 무연고자, 「사회복지사업법」에 따른 사회복지시설 입소자 중 무연고자</li>
                </ul>

                <div className={styles.tableDiv}>
                    <table>
                        <thead>
                            <th>구분</th>
                            <th>단위</th>
                            <th>관내주민(50% 감액)</th>
                            <th>관외주민</th>
                            <th>비고</th>
                        </thead>
                        <tbody>
                            <tr>
                                <td rowSpan={2} className={styles.first}>개인단</td>
                                <td>최초사용료</td>
                                <td>165,000</td>
                                <td rowSpan={2}></td>
                                <td rowSpan={2}>감면대상자에 한함</td>
                            </tr>
                            <tr>
                                <td>연장사용료</td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </section>
        </main>
    )

}