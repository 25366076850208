import React from "react"
import styles from "./Crematorium.module.scss";
import crematorium from "./imgs/crematoriumImg.png";

// * 승화원
const Crematorium : React.FC = () => {
    return(
        <main className={styles.container}>
            <article className={styles.sub_container}>
                <section className={styles.callout}><div>승화원 운영시간 <span>(07:00~16:00)</span></div></section>
                <section  className={styles.contents}>
                    <img src={crematorium} alt="파일:승화원"/>
                    <div className={styles.crematorium_description}>
                        <h3>&lt;승화원&gt;</h3>
                        <ul>
                            <li>시신 또는 유골을 화장하기 위한 시설입니다.</li>
                            <li>전국 최초의 3연차 연소 공해방지 시스템 도입을 통해 
                                무색, 무취, 무연의 완전연소 시스템을 갖추었습니다.</li>
                        </ul>
                    </div>
                </section>
            </article>


            <article className={styles.sub_container}>
                <h3 className={styles.subheading}>이용안내</h3>
                <section>
                    <ul>
                        <li>서류접수는 <span className={styles.font_red}>화장시작 시간 1시간 전부터 진행</span>하며 회차 화장시작 시간 전까지 접수를 완료하여야 합니다.</li>
                        <li>단, 설날과 추석은 운영하지 않습니다.</li>
                    </ul>
                </section>
                <section className={styles.table_container}>
                    <table>
                        <thead>
                            <th>차수</th>
                            <th>1회차</th>
                            <th>2회차</th>
                            <th>3회차</th>
                            <th>4회차</th>
                            <th>5회차</th>
                            <th>6회차</th>
                            <th>7회차</th>
                            <th>8회차</th>
                        </thead>
                        <tbody>
                            <tr>
                                <td>화장시간</td>
                                <td>08:00</td>
                                <td>09:00</td>
                                <td>10:00</td>
                                <td>11:00</td>
                                <td>12:00</td>
                                <td>13:00</td>
                                <td>14:00</td>
                                <td>15:00</td>
                            </tr>
                            <tr>
                                <td>화장대상</td>
                                <td>관내</td>
                                <td>관내</td>
                                <td>관내</td>
                                <td>관내</td>
                                <td>관내/관외</td>
                                <td>관내/관외</td>
                                <td>관내/관외<br/>개장유골<br/>죽은 태아</td>
                                <td>관내/관외<br/>개장유골<br/>죽은 태아</td>
                            </tr>
                        </tbody>
                    </table>
                </section>
                <section>
                    <ul  className={styles.warning_ul}>
                        <li>이전 회차에 접수가능한 화로가 있을 경우 한 회차 앞으로 옮겨서 화장한다.</li>
                        <li>단, 개장유골 화장에 한하여 7‧8회차에 화장 신청 후, 1~6회차 여유화로가 있을 시 혼인관계 확인 후 동회 차에 화장 진행</li>
                        <li>해당되는 화장 시작시간 이후 접수가 이루어진 경우 다음 회차 마지막 순번으로 화장한다.</li>
                    </ul>
                </section>
            </article>


            <article className={styles.sub_container}>
                <h3 className={styles.subheading}>사용대상</h3>
                <ul>
                    <li>승화원은 누구든지 사용할 수 있습니다.</li>
                </ul>
            </article>


            <article className={styles.sub_container}>
                <h3 className={styles.subheading}>안장절차</h3>
                <section className={styles.table_container}>
                    <table>
                        <tbody>
                            <tr >
                                <td >1. [e-하늘] 화장예약</td>
                                <td>(보건복지부)</td>
                            </tr>
                            <tr>
                                <td>2. 화장장도착 및 화장접수</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>3. 운구</td>
                                <td>(장례지도사)</td>
                            </tr>
                            <tr>
                                <td>4. 고별식</td>
                                <td>유가족은 유족대기실로 이동</td>
                            </tr>
                            <tr>
                                <td>5. 화장</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>6. 수골</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>7. 유골 봉안 도는 반출</td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                </section>
            </article>


            <article className={styles.sub_container}>
                <h3 className={styles.subheading}>구비서류</h3>
                <section className={styles.table_container}>
                    <table>
                        <thead>
                            <th></th>
                            <th>방문하여 작성할 서류</th>
                            <th>사용자 필수 지참 서류</th>
                            <th>울산하늘공원 확인 가능 서류</th>
                        </thead>
                        <tbody>
                            <tr>
                                <td>병사</td>
                                <td>울산하늘공원 사용 허가 신청서</td>
                                <td>사망진단서</td>
                                <td>
                                    <div>
                                        <ul>
                                            <li>주민등록등본(고인)</li>
                                            <li>국가유공자증명</li>
                                            <li>국민기초생활수급증명</li>
                                        </ul>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>외인사</td>
                                <td>울산하늘공원 사용 허가 신청서</td>
                                <td>시체 검안서, 검사 지휘서</td>
                                <td>
                                    <div>
                                        <ul>
                                            <li>주민등록등본(고인)</li>
                                            <li>국가유공자증명</li>
                                            <li>국민기초생활수급증명</li>
                                        </ul>
                                    </div>

                                </td>
                            </tr>
                            <tr>
                                <td>외국인</td>
                                <td>울산하늘공원 사용 허가 신청서</td>
                                <td>
                                    <div>
                                        <ul>
                                            <li>사망진단서</li>
                                            <li>대사관 화장 동의서<br/>(대사관 사망확인서)</li>
                                        </ul>
                                    </div>

                                </td>
                                <td>외국인등록증</td>
                            </tr>
                            <tr>
                                <td>죽은태아</td>
                                <td>울산하늘공원 사용 허가 신청서</td>
                                <td>사산확인서</td>
                                <td>주민등록등본(부모)</td>
                            </tr>
                            <tr>
                                <td>개장유골</td>
                                <td>울산하늘공원 사용 허가 신청서</td>
                                <td>
                                    <div>
                                        <ul>
                                            <li>말소자 등·초본</li>
                                            <li>개장신고필증</li>
                                        </ul>
                                    </div>
                                </td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                </section>
                <section>
                    <ul className={styles.warning_ul}>
                        <li className={styles.font_red}>시설사용 신청인은 신분증을 지참하셔야 합니다.</li>
                        <li className={styles.font_red}>외국인일 경우 구비서류가 외국어로 기재되어 있는 경우에는 번역공증을 하여야 합니다.</li>
                    </ul>
                </section>
            </article>

            <article className={styles.sub_container}>
                <h3 className={styles.subheading}>시설 사용료</h3>
                <section className={styles.table_container}>
                    <table>
                        <thead>
                            <th>구분</th>
                            <th>내용</th>
                            <th>단위</th>
                            <th>관내주민</th>
                            <th>관외주민</th>
                            <th>비고</th>
                        </thead>

                        <tbody>
                            <tr>
                                <td className={styles.table_title} rowSpan={4}>승화원</td>
                                <td>대인(만 14세 이상)</td>
                                <td>1구당</td>
                                <td>140,000</td>
                                <td>800,000</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>소인(만 13세 이하)</td>
                                <td>1구당</td>
                                <td>120,000</td>
                                <td>500,000</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>죽은 태아</td>
                                <td>1구당</td>
                                <td>50,000</td>
                                <td>250,000</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>개장 1유골</td>
                                <td>1구당</td>
                                <td>60,000</td>
                                <td>300,000</td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                </section>
                <section>
                    <ul className={styles.warning_ul}>
                        <li className={styles.font_green} >관내주민이란 사망 당시에 주민등록이 울산광역시에 되어 있는 자를 말합니다.</li>
                        <li>개장유골의 경우 관내에서 분묘를 개장한 경우를 포함합니다.</li>
                    </ul>
                </section>
            </article>


            <article className={styles.sub_container}>
                <h3 className={styles.subheading}>사용료 면제(감면 적용 사용료)</h3>
                <section>
                    <ul>
                        <li className={styles.font_green}>
                            <span>일반감면</span>
                            <ul className={`${styles.font_black} ${styles.sub_ul}`}>
                                <li>생계·의료급여 수급자, 국가유공자와 그 배우자, 무연고자, 특별한 사정이 있다고 시장이 인정한 자</li>
                            </ul>
                        </li>
                        <li className={styles.font_green}>
                            <span>관내 승화원 면제</span>
                            <ul className={`${styles.font_black} ${styles.sub_ul}`}>
                                <li>관내에 거주하는 100세 이상, 장기·인체조직 기증자, 의로운 시민 </li>
                            </ul>
                        </li>
                        <li className={styles.font_green}>
                            <span>관외 승화원 50%감면</span>
                            <ul className={`${styles.font_black} ${styles.sub_ul}`}>
                                <li>등록기준지가 울산광역시로 되어 있는 사람</li>
                            </ul>
                        </li>
                        <li className={styles.font_green}>
                            <span>관외 승화원 30%감면</span>
                            <ul className={`${styles.font_black} ${styles.sub_ul}`}>
                                <li>울산광역시에서 표창장 등을 받은 우수기업인 또는 근로자</li>
                                <li>관내에서 1년 이상 거주자 또는 1년 이상 지방세 납부자</li>
                            </ul>
                        </li>
                    </ul>
                </section>
                <section className={styles.table_container}>
                    <table>
                        <thead>
                            <tr>
                                <th rowSpan={2}>구분</th>
                                <th rowSpan={2}>내용</th>
                                <th colSpan={5}>관내</th>
                                <th colSpan={4}>관외</th>
                            </tr>
                            <tr>
                                <th>일반</th>
                                <th>삼동</th>
                                <th>일반감면</th>
                                <th>승회원 면제</th>
                                <th>일반</th>
                                <th>일반감면</th>
                                <th>양산</th>
                                <th>승회원 50% 감면</th>
                                <th>승회원 30% 감면</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className={styles.table_title} rowSpan={4}>승회원</td>
                                <td>대인<br/>(만 14세 이상)</td>
                                <td>140,000</td>
                                <td>28,000</td>
                                <td>0</td>
                                <td>0</td>
                                <td>800,000</td>
                                <td>0</td>
                                <td>300,000</td>
                                <td>400,000</td>
                                <td>560,000</td>
                            </tr>
                            <tr>
                                <td>소인<br/>(만 13세 이하)</td>
                                <td>120,000</td>
                                <td>24,000</td>
                                <td>0</td>
                                <td>0</td>
                                <td>500,000</td>
                                <td>0</td>
                                <td>200,000</td>
                                <td>250,000</td>
                                <td>350,000</td>
                            </tr>
                            <tr>
                                <td>죽은태아</td>
                                <td>50,000</td>
                                <td>10,000</td>
                                <td>X</td>
                                <td>X</td>
                                <td>250,000</td>
                                <td>0</td>
                                <td>100,000</td>
                                <td>X</td>
                                <td>X</td>
                            </tr>
                            <tr>
                                <td>개장유골</td>
                                <td>60,000</td>
                                <td>12,000</td>
                                <td>X</td>
                                <td>X</td>
                                <td>300,000</td>
                                <td>0</td>
                                <td>120,000</td>
                                <td>X</td>
                                <td>X</td>
                            </tr>
                        </tbody>
                    </table>
                </section>
                <section>
                    <ul className={styles.warning_ul}>
                        <li>울주군 삼동면에 주민등록이 되어 있는 자가 사망 후 승화원을 사용하는 경우에는 사용료의 100분의 80을 감면합니다.</li>
                    </ul>
                </section>
            </article>
            <article className={styles.sub_container}></article>


        </main>
    )
}

export default Crematorium;