import Topbar from "components/TopBar/Topbar";
import styles from "./PageFrame.module.scss";
import {
  MenuItem,
  sideIntroData,
  sideFacilitiesData,
  sideUsageData,
  sideProcedureData,
  sideNoticesData,
  sideSiteMapData,
} from "./navData";
import { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import arrow from "../../assets/imgs/Sidebar/arrow.svg";
import home from "../../assets/imgs/Sidebar/home.svg";

interface SidebarProps {
  type:
    | "introduction"
    | "facilities"
    | "usage"
    | "procedure"
    | "notices"
    | "sitemap";
}

interface MenuItemProps {
  data: MenuItem;
}

interface NavPathProps {
  data: MenuItem;
  children: React.ReactNode;
}

//* Sidebar 컴포넌트입니다. menuitem을 props로 받습니다.
/** Sidebar 컴포넌트입니다. typq을 props로 받는다.
 * @param type - 'introduction' | 'facilities' |  'usage' | 'procedure'| 'notices' | 'sitemap' ;
}
 */
const Sidebar: React.FC<SidebarProps> = ({ type }) => {
  // * 네비게이트 선언
  const navigate = useNavigate();
  const [selectedKey, setSelectedKey] = useState<string | null>(null); // 선택된 메뉴의 URL을 상태로 관리
  const location = useLocation();

  // * url 이동 함수 , url (string) 을 받는다.
  const goURL = (url?: string) => {
    if (url) {
      setSelectedKey(url); // 선택된 아이템 key로 설정
      navigate(url);
      // alert(url);
    }
  };

  // * 현재 경로에 맞는 메뉴 아이템을 자동으로 선택
  useEffect(() => {
    const currentPath = location.pathname;
    if (currentPath.split("/")[1] === "notices") {
      // 공지사항일 경우는 직접 지정.
      setSelectedKey("/notices");
    } else {
      setSelectedKey(currentPath); // 현재 경로에 해당하는 URL을 selectedKey로 설정
    }
  }, [location.pathname]); // pathname과 바뀔때마다 실행된다.

  const SidebarComponent: React.FC<MenuItemProps> = ({ data }) => {
    return (
      <aside className={styles.container}>
        <nav className={styles.nav_container}>
          <header className={styles.title_sidebar}>{data.title}</header>
          <ol className={styles.menu_container}>
            {data.subMenus.map((subMenu, index) => (
              <li className={styles.subMenu_container}>
                {subMenu.title === null ? (
                  <></>
                ) : (
                  <div
                    className={`${styles.subMenu_title}`}
                    // ${
                    //   selectedKey === subMenu.title?.url ? styles.clicked : ""
                    // }`}
                    // key={subMenu.title?.url} // * key 값을 url로 지정
                    // onClick={() => {
                    //   goURL(subMenu.title?.url);
                    // }}
                  >
                    <span className={styles.item_text}>
                      {" "}
                      {subMenu.title?.name}{" "}
                    </span>
                    <img
                      className={
                        selectedKey === subMenu.title?.url
                          ? styles.clicked_img
                          : styles.item_img
                      }
                      src={arrow}
                      alt=""
                    />
                  </div>
                )}
                <ol className={styles.subMenu_item_container}>
                  {subMenu.items?.map((subMenuItem, index) => (
                    <li
                      className={`${styles.subMenu_item} ${
                        selectedKey === subMenuItem.url ? styles.clicked : ""
                      }`}
                      key={subMenuItem.url} // * key갑을 url로 지정
                      onClick={() => {
                        goURL(subMenuItem.url);
                      }}
                    >
                      <span className={styles.item_text}>
                        {" "}
                        {subMenuItem?.name}{" "}
                      </span>
                      <img
                        className={
                          selectedKey === subMenuItem.url
                            ? styles.clicked_img
                            : styles.item_img
                        }
                        src={arrow}
                        alt=""
                      />
                    </li>
                  ))}
                </ol>
              </li>
            ))}
          </ol>
        </nav>
      </aside>
    );
  };

  const NavPathComponent: React.FC<NavPathProps> = ({ data, children }) => {
    let menuName;
    return (
      <div className={styles.page_content}>
        <ul className={styles.nav}>
          <li>
            <img src={home} alt="홈" />
          </li>
          <li>{data.title}</li>
          {data.subMenus.map((subMenu) => (
            <>
              {selectedKey?.startsWith(subMenu.title?.url!) && (
                <li>{subMenu.title?.name}</li>
              )}
              {subMenu.items?.map(
                (subMenuItem) =>
                  selectedKey === subMenuItem.url && (
                    <li>{(menuName = subMenuItem?.name)}</li>
                  )
              )}
            </>
          ))}
        </ul>
        <h1 className={styles.page_header}>{menuName || "공지사항"}</h1>
        {children}
      </div>
    );
  };

  return (
    <div className={styles.page}>
      {type === "introduction" ? (
        <>
          <SidebarComponent data={sideIntroData} />
          <NavPathComponent data={sideIntroData}>
            <Outlet />
          </NavPathComponent>
        </>
      ) : type === "facilities" ? (
        <>
          <SidebarComponent data={sideFacilitiesData} />
          <NavPathComponent data={sideFacilitiesData}>
            <Outlet />
          </NavPathComponent>
        </>
      ) : type === "usage" ? (
        <>
          <SidebarComponent data={sideUsageData} />
          <NavPathComponent data={sideUsageData}>
            <Outlet />
          </NavPathComponent>
        </>
      ) : type === "procedure" ? (
        <>
          <SidebarComponent data={sideProcedureData} />
          <NavPathComponent data={sideProcedureData}>
            <Outlet />
          </NavPathComponent>
        </>
      ) : type === "notices" ? (
        <>
          <SidebarComponent data={sideNoticesData} />
          <NavPathComponent data={sideNoticesData}>
            <Outlet />
          </NavPathComponent>
        </>
      ) : type === "sitemap" ? (
        <>
          <SidebarComponent data={sideSiteMapData} />
          <NavPathComponent data={sideSiteMapData}>
            <Outlet />
          </NavPathComponent>
        </>
      ) : (
        <>
          <SidebarComponent data={sideIntroData} />
          <NavPathComponent data={sideIntroData}>
            <Outlet />
          </NavPathComponent>
        </>
      )}
    </div>
  );
};

export default Sidebar;
