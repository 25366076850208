import React from 'react';
import styles from './Info.module.scss';
import burningIncenseImg from "./imgs/burningIncenseImg.png"
import layingFlowersImg from "./imgs/layingFlowersImg.png"

import girlHandOver from "./imgs/girlHandOver.png"
import manHandOver from "./imgs/manHandOver.png"

import funeralEtiquette from './imgs/funeralEtiquette.png'

// 예법
const Info : React.FC = () => {
    return(
        <main className={styles.container}>
            <article className={styles.sub_container}>
                <h3 className={styles.subheading}>장례예절(출처: 네이버 블로그_재능꾼)</h3>
                <div className={styles.contents}>
                    <img src={funeralEtiquette} alt="파일:장례예절" />
                </div>
            </article>

            <article className={styles.sub_container}>
                <h3 className={styles.subheading}>문상객록 서명 후 분향 또는 헌화법</h3>
                <div className={styles.contents}>
                    <section>
                        <li>분향 시</li>
                        <div>
                            <img className={styles.img_bunhyang} src={layingFlowersImg} alt="파일:분향"/>
                        </div>
                    </section>
                    <section>
                        <li>헌화 시</li>
                        <div>
                            <img className={styles.img_heonhwa} src={burningIncenseImg} alt="파일:헌화"/>
                        </div>
                    </section>
                </div>
            </article>

            <article className={styles.sub_container}>
                <h3 className={styles.subheading}>공수법(고인에게 예를 표함)</h3>
                <div className={styles.contents}>
                    <section>
                        <li>남성(큰절) - <span>오른손</span>이 위</li>
                        <div>
                            <img className={styles.img_man_handover} src={manHandOver} alt="파일:남성(큰절)"/>
                        </div>
                    </section>
                    <section>
                        <li>여성(큰절) - <span>왼손</span>이 위</li>
                        <div>
                            <img  className={styles.img_girl_handover} src={girlHandOver} alt="파일:여성(큰절)"/>
                        </div>
                    </section>
                </div>
            </article>

            <article className={styles.sub_container}>
                <h3 className={styles.subheading}>문상예절</h3>
                <ol className={styles.contents}>
                    <li>영좌에서 물러나와 상주와 맞절합니다.</li>
                    <li>절을 한 후에 간단한 인사말을 건네도 좋지만 기본적으로 아무말하지 않는 것이 일반적입니다.</li>
                    <li>문상이 끝난 후에는 두세 걸음 뒤로 물러난 뒤 몸을 돌려 나오는 것이 예의입니다.</li>
                </ol>
            </article>

            <article className={styles.sub_container}>
                <h3 className={styles.subheading}>주의사항</h3>
                <ol className={styles.contents}>
                    <li>상주, 상제에게 악수를 청하는 행동을 삼가고 인사는 목례로 대신합니다.</li>
                    <li>반가운 지인을 만나더라도 큰소리로 이름을 부르지 않습니다.</li>
                    <li>유가족에게 계속 말을 시키거나 고인의 사망 원인을 상세히 묻는 것은 실례입니다.</li>
                    <li>장례식장에서 술을 마실 때는 본인이 본인 잔을 채워서 마시는 것이 좋고 건배도 해서는 안됩니다.</li>
                    <li>과도한 음식 및 도박 행위는 삼가하도록 합니다.</li>
                </ol>
            </article>
        </main>
    )
}

export default Info;