import styles from "./DeathbedAfter.module.scss";
import Arrow from "assets/imgs/RightArrow.svg";
import { useMediaQuery } from "../../../Introduction/Philosophy/useMediaQuery";
import img1 from "./imgs/Frame 690.svg";
import img2 from "./imgs/Frame 691.svg";

const DeathbedBefore = () => {
  const isMobile = useMediaQuery("(max-width: 1023px)");

  return (
    <div className={styles.container}>
      <section className={styles.contentSection}>
        <h3 className={styles.subheading}>임종 후(외인 사)</h3>
        <ul>
          <li className={styles.highlights}>
            외인 사: 고인이 병원을 제외한 곳에서 사망을 하였을 경우
          </li>
          <li>
            외인 사 경우 검안서 발부가 되어야 장례 진행 을 진행 할 수 있습니다.
          </li>
          <li>집에서 임종을 하셔도 외인 사로 분류가 됩니다.</li>
        </ul>

        <div className={styles.flowchart}>
          <div className={styles.flowchartRow}>
            <div className={styles.flowchartItem}>
              <div className={styles.box}>112(경찰)신고접수</div>
              <div className={styles.arrow} />
            </div>
            <div className={styles.flowchartItem}>
              <div className={styles.box}>
                울산하늘공원 장례식장 <br />
                (배차 접수 및 빈소 확인 전화)
              </div>
              <div className={styles.arrow} />
            </div>
            <div className={styles.flowchartItem}>
              <div className={`${styles.box} ${styles.red}`}>
                사망 현장 확인 및 검안서 발부
              </div>
            </div>
          </div>
          <div className={styles.flowchartRow2}>
            <div className={styles.flowchartItem}>
              <div className={styles.arrow} />
            </div>
            <div className={styles.flowchartItem}>
              <div className={styles.box}>고인 장례식장 이송</div>
              <div className={styles.arrow} />
            </div>

            <div className={styles.flowchartItem}>
              <div className={styles.box}>
                울산하늘공원 장례식장 <br />
                장례 절차 상담, 빈소 입실
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className={styles.contentSection2}>
        <h3 className={styles.subheading}>임종 후(병사, 자연사)</h3>
        <ul>
          <li className={styles.highlights}>
            요양병원, 병원에서 임종을 하셨을 경우
          </li>
          <li>
            <p>
              고인 임종 후 사망진단서가 발급이 되어야 장례 절차가 진행 됩니다.
            </p>
          </li>
        </ul>

        <div className={styles.flowchart}>
          <div className={styles.flowchartRow}>
            <div className={styles.flowchartItem}>
              <div className={styles.box}>
                울산하늘공원 장례식장
                <br />
                (배차 접수 및 빈소확인 전화)
              </div>
              <div className={styles.arrow} />
            </div>
            <div className={styles.flowchartItem}>
              <div className={`${styles.box} ${styles.red}`}>
                사망진단서 발부
              </div>
              <div className={styles.arrow} />
            </div>
            <div className={styles.flowchartItem}>
              <div className={styles.box}>고인 장례식장 이송</div>
            </div>
          </div>

          <div className={styles.flowchartRow2}>
            <div className={styles.flowchartItem}>
              <div className={styles.arrow} />
            </div>
            <div className={styles.flowchartItem}>
              <div className={styles.box}>
                울산하늘공원 장례식장
                <br />
                장례 절차 상담, 빈소 입실
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default DeathbedBefore;
