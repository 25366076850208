import { useState, useEffect } from "react";

export const useMediaQuery = (query) => {
  const [matches, setMatches] = useState(window.matchMedia(query).matches);

  useEffect(() => {
    const mediaQueryList = window.matchMedia(query);
    const documentChangeHandler = () => setMatches(mediaQueryList.matches);

    // 이벤트 리스너 등록
    mediaQueryList.addEventListener("change", documentChangeHandler);
    
    // 초기 쿼리 결과 확인
    documentChangeHandler();

    // 클린업 함수에서 이벤트 리스너 제거
    return () => mediaQueryList.removeEventListener("change", documentChangeHandler);
  }, [query]);

  return matches;
};
