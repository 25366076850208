import styles from "./DiningFacilities.module.scss";
import res from "./imgs/image 91.png";
import store from "./imgs/image 92.png";
import cafe from "./imgs/image 93.png";
import res1 from "./imgs/image 94.png";
import res2 from "./imgs/image 95.png";
import res3 from "./imgs/image 96.png";
import res4 from "./imgs/image 97.png";
import flower1 from "./imgs/image 98.png";
import flower2 from "./imgs/image 99.png";

export default function DiningFacilities() {
  type ItemWithHot = { name: string; hot: string; ice: string };
  type ItemWithoutHot = { name: string; ice: string };

  function hasHot(item: ItemWithHot | ItemWithoutHot): item is ItemWithHot {
    return "hot" in item;
  }

  const data = [
    {
      category: "커피(COFFEE)",
      menus: [
        { name: "티(TEA)" },
        { name: "커피(COFFEE)" },
        { name: "음료(BEVERAGE), 디저트(DESSERT)" },
      ],
      items: [
        { name: "녹차", hot: "에스프레소", ice: "생과일 주스(딸기,바나나)" },
        { name: "허브티(페퍼민트)", hot: "아메리카노", ice: "레몬에이드" },
        { name: "유자차", hot: "카페라떼", ice: "복숭아아이스티" },
        { name: "홍차", hot: "카푸치노", ice: "머핀(초코,블루베리)" },
        { name: "생강차", hot: "카라멜 라떼", ice: "" },
        { name: "대추차", hot: "바닐라 라떼", ice: "" },
        { name: "우유", hot: "카페 모카", ice: "" },
        { name: "곡물라떼", hot: "카라멜 마키아토", ice: "" },
        { name: "녹차라떼", hot: "", ice: "" },
        { name: "초코라떼", hot: "", ice: "" },
        { name: "고구마라떼", hot: "", ice: "" },
      ],
    },
  ];

  return (
    <main className={styles.container}>
      <article className={styles.opentime}>
        <div className={styles.openTimeContainer}>
          <h3 className={styles.subheading2}>운영시간</h3>
          <ul className={styles.description}>
            <li>
              식당: <span>08:00 ~ 14:00</span>
            </li>
            <li>
              매점(꽃): <span>08:00 ~ 22:00</span>
            </li>
            <li>
              카페: <span>08:00 ~ 15:00</span>
            </li>
          </ul>
        </div>

        <div className={styles.tableDiv}>
          <table>
            <thead>
              <th>식당</th>
              <th>매점(꽃)</th>
              <th>카페</th>
            </thead>
            <tbody>
              <tr>
                <td>
                  <img src={res} alt="" />
                </td>
                <td>
                  <img src={store} alt="" />
                </td>
                <td>
                  <img src={cafe} alt="" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </article>

      <article className={styles.restaurant}>
        <h3 className={styles.subheading2}>식당</h3>
        <div className={styles.restaurantTable}>
          <table>
            <thead>
              <th>육개장</th>
              <th>설렁탕</th>
              <th>황태국</th>
              <th>어묵 우동</th>
            </thead>
            <tbody>
              <tr>
                <td>
                  <img src={res1} alt="" />
                </td>
                <td>
                  <img src={res2} alt="" />
                </td>
                <td>
                  <img src={res3} alt="" />
                </td>
                <td>
                  <img src={res4} alt="" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </article>

      <article className={styles.flowerstore}>
        <h3 className={styles.subheading2}>매점(꽃)</h3>
        <div className={styles.flowerStoreTable}>
          <table>
            <thead>
              <th>꽃 다발</th>
              <th>꽃 바구니</th>
            </thead>
            <tbody>
              <tr>
                <td>
                  <img src={flower1} alt="" height="200px" />{" "}
                </td>
                <td>
                  <img src={flower2} alt="" height="200px" />{" "}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </article>

      <article className={styles.cafe}>
        <h3 className={styles.subheading2}>카페</h3>
        {data.map((section, index) => (
          <div className={styles.cafeTable} key={index}>
            <table>
              <thead>
                {section.menus.map((menu, idx2) => (
                  <th> <li className={styles.customDot}>{menu.name}</li></th>
                ))}
              </thead>
              <tbody>
                {section.items.map((item, idx) => (
                  <tr key={idx}>
                    <td>{item.name}</td>
                    <td>{item.hot}</td>
                    <td>{item.ice}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ))}
      </article>
    </main>
  );
}
