import styles from "./ArticlePage.module.scss";
import { useParams } from "react-router-dom";
import { GetArticle, Article, GetArticleCount } from "./ariticleData";
import { useNavigate } from "react-router-dom";

function formatDate(date: Date): string {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
}

const ArticlePage = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  let article: Article | undefined;
  if (id === undefined) {
    return <div>404</div>;
  } else {
    article = GetArticle(parseInt(id));
  }

  return (
    <div className={styles.container}>
      <h2 className={styles.header}>{article?.title}</h2>
      <div className={styles.info}>
        <span>작성자: {article?.author}</span>
        <span>{formatDate(article?.date!)}</span>
        <span>조회수: {article?.view}</span>
      </div>
      <div className={styles.content}>{article?.content}</div>
      <div className={styles.navButton}>
        <button
          onClick={() => {
            navigate(
              "/notices/" +
                (parseInt(id) < GetArticleCount()
                  ? parseInt(id) + 1
                  : parseInt(id)
                ).toString()
            );
          }}
        ></button>
        <button
          onClick={() => {
            navigate("/notices");
          }}
        >
          목록
        </button>
        <button
          onClick={() => {
            navigate(
              "/notices/" +
                (parseInt(id) > 0 ? parseInt(id) - 1 : parseInt(id)).toString()
            );
          }}
        ></button>
      </div>
    </div>
  );
};

export default ArticlePage;
