import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./FacilityCarousel.scss";
import Image1 from "pages/Facilities/FuneralOffice/imgs/1.png";
import Image2 from "pages/Facilities/PreparationRoom/imgs/1.png";
import Image3 from "pages/Facilities/Office/imgs/1.png";
import Image4 from "pages/Facilities/Reception/imgs/1.png";
import Image5 from "pages/Facilities/FamilyWaitingRoom/imgs/1.png";
import Image6 from "pages/Facilities/MourningRoom/imgs/1.png";
import Image7 from "pages/Facilities/FamilyLounge/imgs/1.png";
import Image8 from "pages/Facilities/Restaurant/imgs/1.png";
import Image9 from "pages/Facilities/Cafe/imgs/1.png";
import { useNavigate } from "react-router-dom";

interface Slide {
  src: any;
  alt: string;
  title: string;
  link: string;
}

const slides: Slide[] = [
  {
    src: Image1,
    alt: "장례 사무실",
    title: "장례 사무실",
    link: `/facilities/1st/funeral-office`,
  },
  {
    src: Image2,
    alt: "입관실",
    title: "입관실",
    link: `/facilities/1st/preparation-room`,
  },
  {
    src: Image3,
    alt: "사무국",
    title: "사무국",
    link: `/facilities/1st/office`,
  },
  {
    src: Image4,
    alt: "각인 정산실",
    title: "각인 정산실",
    link: `/facilities/1st/reception`,
  },
  {
    src: Image5,
    alt: "가족 대기실",
    title: "가족 대기실",
    link: `/facilities/1st/family-waiting-room`,
  },
  {
    src: Image6,
    alt: "빈소",
    title: "빈소",
    link: `/facilities/2nd/mourning-room`,
  },
  {
    src: Image7,
    alt: "가족 휴게실",
    title: "가족 휴게실",
    link: `/facilities/2nd/family-lounge`,
  },
  {
    src: Image8,
    alt: "식당, 매점",
    title: "식당, 매점",
    link: `/facilities/2nd/restaurant`,
  },
  {
    src: Image9,
    alt: "카페",
    title: "카페",
    link: `/facilities/2nd/cafe`,
  },
];

const settings = {
  infinite: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 2,
  arrows: true,
  centerMode: false,
  initialSlide: 0,
  variableWidth: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
      },
    },
  ],
};

const FacilityCarousel: React.FC = () => {
  const handleSlideClick = (link: string) => {
    window.location.href = link;
  };

  const navigate = useNavigate();

  return (
    <div className="facility-carousel">
      <Slider {...settings}>
        {slides.map((slide, index) => (
          <div
            key={index}
            className="facilityItem"
            onClick={() => navigate(slide.link)}
          >
            <div className="overlay"></div>
            <img className="facilityImage" src={slide.src} alt={slide.alt} />
            <p className="title">{slide.title}</p>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default FacilityCarousel;
