import React, { useState } from "react";
import s from "./SiteMap.module.scss";
import { Link } from "react-router-dom";
import { useMediaQuery } from "../Introduction/Philosophy/useMediaQuery";

export default function Sitemap() {

    type Section = 'introduction' | 'firstFloor' | 'secondFloor' | 'funeralGuide' | 'facilityGuide' | 'procedure' | 'info';
    
    const isMobile = useMediaQuery("(max-width: 1023px)");

    const [openSections, setOpenSections] = useState({
        introduction: false,
        firstFloor: false,
        secondFloor: false,
        funeralGuide: false,
        facilityGuide: false,
        procedure: false,
        info: false,
    });

    const toggleSection = (section: Section) => {
        setOpenSections((prevSections) => ({
            ...prevSections,
            [section]: !prevSections[section],
        }));
    };

    return (
        <>
          {isMobile ? (
                <main className={s.siteMapContainer}>
                {/* 소개 섹션 */}
                <section className={s.box} onClick={() => toggleSection("introduction")}>
                    <div className={s.headBox}>
                        소개
                    </div>
                    {openSections.introduction && (
                        <div className={s.linkBox}>
                            <Link to="/introduction/directions">경영이념</Link>
                            <Link to="/introduction/greeting">인사말</Link>
                            <Link to="/introduction/philosophy">오시는 길</Link>
                        </div>
                    )}
                </section>

                {/* 1층 섹션 */}
                <section className={s.box} onClick={() => toggleSection("firstFloor")}>
                    <div className={s.headBox}>
                        1층
                    </div>
                    {openSections.firstFloor && (
                        <div className={s.linkBox}>
                            <Link to="/facilities/1st/funeral-office">장례 사무실</Link>
                            <Link to="/facilities/1st/preparation-room">입관실, 안치실</Link>
                            <Link to="/facilities/1st/office">사무국</Link>
                            <Link to="/facilities/1st/reception">봉안함, 위패 접수 및 정산실</Link>
                            <Link to="/facilities/1st/family-waiting-room">가족 대기실</Link>
                        </div>
                    )}
                </section>

                {/* 2층 섹션 */}
                <section className={s.box} onClick={() => toggleSection("secondFloor")}>
                    <div className={s.headBox}>
                        2층
                    </div>
                    {openSections.secondFloor && (
                        <div className={s.linkBox}>
                            <Link to="/facilities/2nd/mourning-room">빈소</Link>
                            <Link to="/facilities/2nd/family-lounge">가족 휴게실</Link>
                            <Link to="/facilities/2nd/restaurant">식당, 매점</Link>
                            <Link to="/facilities/2nd/cafe">카페</Link>
                        </div>
                    )}
                </section>

                {/* 장례식장 이용안내 섹션 */}
                <section className={s.box} onClick={() => toggleSection("funeralGuide")}>
                    <div className={s.headBox}>
                        장례식장 이용안내
                    </div>
                    {openSections.funeralGuide && (
                        <div className={s.linkBox}>
                            <Link to="/usage/funeral/funeral-items">장례용품</Link>
                            <Link to="/usage/funeral/funeral-hall">빈소, 오브제(화환)</Link>
                            <Link to="/usage/funeral/memorial-engraving">봉안함, 위패, 표지석 각인</Link>
                            <Link to="/usage/funeral/memorial-ceremony">제례상(49상, 기제사)</Link>
                            <Link to="/usage/funeral/dining-facilities">식당, 매점(꽃), 카페</Link>
                        </div>
                    )}
                </section>

                {/* 부대시설 이용안내 섹션 */}
                <section className={s.box} onClick={() => toggleSection("facilityGuide")}>
                    <div className={s.headBox}>
                        부대시설 이용안내
                    </div>
                    {openSections.facilityGuide && (
                        <div className={s.linkBox}>
                            <Link to="/usage/facilities/crematorium">승하원</Link>
                            <Link to="/usage/facilities/natural-burial">자연장치(수목장, 잔디장)</Link>
                            <Link to="/usage/facilities/memorial-house">추모의 집</Link>
                            <Link to="/usage/facilities/memorial-park">유택동산</Link>
                        </div>
                    )}
                </section>

                {/* 장례절차 섹션 */}
                <section className={s.box} onClick={() => toggleSection("procedure")}>
                    <div className={s.headBox}>
                        장례절차
                    </div>
                    {openSections.procedure && (
                        <div className={s.linkBox}>
                            <Link to="/guide/procedure/before-death">임종 전</Link>
                            <Link to="/guide/procedure/after-death">임종 후</Link>
                            <Link to="/guide/procedure/after-funeral">장례 후</Link>
                        </div>
                    )}
                </section>

                {/* 장례 정보 섹션 */}
                <section className={s.box}  onClick={() => toggleSection("info")}>
                    <div className={s.headBox}>
                        장례 정보
                    </div>
                    {openSections.info && (
                        <div className={s.linkBox}>
                            <Link to="/guide/info">예법</Link>
                            <Link to="/guide/info/ancestral-rites">제례</Link>
                        </div>
                    )}
                </section>
            </main>

          ) : (
            
            <main className={s.siteMapContainer}>
                <section className={s.box}>
                    <div className={s.headBox}>소개</div>
                    <div className={s.linkBox}>
                        <Link to="/introduction/directions">경영이념</Link>
                        <Link to="/introduction/greeting">인사말</Link>
                        <Link to="/introduction/philosophy">오시는 길</Link>
                    </div>
                </section>

                <section className={s.box}>
                    <div className={s.headBox}>1층</div>
                    <div className={s.linkBox}>
                        <Link to="/facilities/1st/funeral-office">장례 사무실</Link>
                        <Link to="/facilities/1st/preparation-room">입관실, 안치실</Link>
                        <Link to="/facilities/1st/office">사무국</Link>
                        <Link to="/facilities/1st/reception">봉안함, 위패  접수 및 정산실</Link>
                        <Link to="/facilities/1st/family-waiting-room">가족 대기실</Link>
                    </div>
                </section>

                <section className={s.box}>
                    <div className={s.headBox}>2층</div>
                    <div className={s.linkBox}>
                        <Link to="/facilities/2nd/mourning-room">빈소</Link>
                        <Link to="/facilities/2nd/family-lounge">가족 휴게실</Link>
                        <Link to="/facilities/2nd/restaurant">식당, 매점</Link>
                        <Link to="/facilities/2nd/cafe">카페</Link>
                    </div>
                </section>

                <section className={s.box2}>
                    <div className={s.headBox}>장례식장 이용안내</div>
                    <div className={s.linkBox}>
                        <Link to="/usage/funeral/funeral-items">장례용품</Link>
                        <Link to="/usage/funeral/funeral-hall">빈소, 오브제(화환)</Link>
                        <Link to="/usage/funeral/memorial-engraving">봉안함, 위패, 표지석 각인</Link>
                        <Link to="/usage/funeral/memorial-ceremony">제례상(49상, 기제사)</Link>
                        <Link to="/usage/funeral/dining-facilities">식당, 매점(꽃), 카페</Link>
                    </div>
                </section>

                <section className={s.box}>
                    <div className={s.headBox}>부대시설 이용안내</div>
                    <div className={s.linkBox}>
                        <Link to="/usage/facilities/crematorium">승하원</Link>
                        <Link to="/usage/facilities/natural-burial">자연장치(수목장, 잔디장)</Link>
                        <Link to="/usage/facilities/memorial-house">추모의 집</Link>
                        <Link to="/usage/facilities/memorial-park">유택동산</Link>
                    </div>
                </section>

                <section className={s.box}>
                    <div className={s.headBox}>장례절차</div>
                    <div className={s.linkBox}>
                        <Link to="/guide/procedure/before-death">임종 전</Link>
                        <Link to="/guide/procedure/after-death">임종 후</Link>
                        <Link to="/guide/procedure/after-funeral">장례 후</Link>
                    </div>
                </section>

                <section className={s.box}>
                    <div className={s.headBox}>장례 정보</div>
                    <div className={s.linkBox}>
                        <Link to="/guide/info">예법</Link>
                        <Link to="/guide/info/ancestral-rites">제례</Link>
                    </div>
                </section>
            </main>
          )}
        </>
      );
    }
            
    