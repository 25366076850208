import styles from "./RoomInfoCard.module.scss";

interface Props {
  readonly deceasedName?: string;
  readonly roomNumber?: number;
  readonly chiefMourner?: string[];
  readonly burialSite?: string;
  readonly casketingTime?: string;
  readonly funeralTime?: string;
}

const RoomInfoCard = ({
  deceasedName = "",
  roomNumber = 0,
  chiefMourner = [],
  burialSite = "",
  casketingTime = "",
  funeralTime = "",
}: Props) => {
  if (!deceasedName)
    return (
      <div className={styles.card}>
        <div className={styles.emptyRoomLabel}>
          <p>
            빈소가
            <br />
            비었습니다.
          </p>
        </div>
      </div>
    );
  else {
    return (
      <div className={styles.card}>
        <p className={styles.deceasedName}>故 {deceasedName}</p>
        <hr className={styles.horizontalRule}></hr>
        <ul className={styles.info}>
          {roomNumber !== 0 && (
            <li className={styles.infoRow}>
              <p className={styles.label}>빈소</p>
              <p className={styles.value}>{roomNumber}호</p>
            </li>
          )}
          {chiefMourner.length !== 0 && chiefMourner.length === 1 ? (
            <li className={styles.infoRow}>
              <p className={styles.label}>상주</p>
              <p className={styles.value}>{chiefMourner[0]}</p>
            </li>
          ) : (
            <li className={styles.infoRow}>
              <p className={styles.label}>상주</p>
              <div className={styles.multilineValue}>
                {chiefMourner.map((item) => (
                  <p className={styles.value}>{item}</p>
                ))}
              </div>
            </li>
          )}
          {burialSite !== "" && (
            <li className={styles.infoRow}>
              <p className={styles.label}>장지</p>
              <p className={styles.value}>{burialSite}</p>
            </li>
          )}
          {casketingTime !== "" && (
            <li className={styles.infoRow}>
              <p className={styles.label}>입관일시</p>
              <p className={styles.value}>{casketingTime}</p>
            </li>
          )}
          {funeralTime !== "" && (
            <li className={styles.infoRow}>
              <p className={styles.label}>발인일시</p>
              <p className={styles.value}>{funeralTime}</p>
            </li>
          )}
        </ul>
      </div>
    );
  }
};

export default RoomInfoCard;
