import styles from "./Philosophy.module.scss";
import { useMediaQuery } from "./useMediaQuery.js"; // 위에서 만든 훅을 가져옴

interface description {
    title : String;
    content : String[];
}

const item: description[] = [
    { title: "고인 존중", content: ["고인의 삶과 명예를 존중하며,", "존엄성을 유지하는 장례 절차를 진행합니다."] },
    { title: "유가족 지원", content: ["유가족의 슬픔을 이해하고,", "정서적, 실질적 지원을 통해 위로와","안정을 제공합니다."] },
    { title: "품의있는 장례정차", content: ["전문적이고 체계적인 장례 절차를 통해", "고인의 마지막 길을 품위 있게 모십니다."] },
    { title: "신뢰 할 수 있는 서비스", content: ["투명한 비용 안내와 신뢰할 수 있는", "서비스를 통해 고객의 신뢰를 확보합니다."] },
    { title: "커뮤니티 참여", content: ["지역사회와 함께 성장하며,", "지역사회에 봉사하는 장례식장이 되겠습니다."] },
    { title: "환경보호", content: ["친환경 장례 옵션을 제공하고,", "지속 가능한 경영을 실천합니다."] }
];

const itemMobile: description[] = [
    { title: "고인 존중", content: ["고인의 삶과 명예를 존중하며,", "존엄성을 유지하는 장례 절차를" , "진행합니다."] },
    { title: "유가족 지원", content: ["유가족의 슬픔을 이해하고,", "정서적, 실질적 지원을 통해"," 위로와 안정을 제공합니다."] },
    { title: "품의있는 장례정차", content: ["전문적이고 체계적인", " 장례 절차를 통해 고인의 마지막" , "길을 품위 있게 모십니다."] },
    { title: "신뢰 할 수 있는 서비스", content: ["투명한 비용 안내와", "신뢰할 수 있는 서비스를 통해", "고객의 신뢰를 확보합니다."] },
    { title: "커뮤니티 참여", content: ["지역사회와 함께 성장하며,", "지역사회에 봉사하는", "장례식장이 되겠습니다."] },
    { title: "환경보호", content: ["친환경 장례 옵션을 제공하고,", "지속 가능한 경영을 실천합니다."] }
];


export default function ManageMent() {
    const isMobile = useMediaQuery("(max-width: 1023px)");

    const currentItem = isMobile ? itemMobile : item;

    return (
        <div className={styles.manageMentContainer}>
            <div className={styles.container}>
                <div className={styles.titleDescription}>
                    <p>
                        &nbsp;울산하늘공원 장례식장(주)는 6가지 경영이념을 원칙으로 고인의 명예를 존중하고, 
                        유가족에게 진심 어린 위로와 최상의 서비스를 제공합니다.
                    </p>
                    <p>
                        "존엄과 품위 있는 장례 절차를 통해 고인의 마지막 길을 함께 하겠습니다."
                    </p>
                </div>

                <div className={styles.boxWrapper}>
                    {currentItem.map((i, index) => (
                        <div className={styles.box} key={index}>
                            <div className={styles.textWrapper}>
                                <div className={styles.title}>{i.title}</div>
                                <div className={styles.content}>
                                    {i.content.map((line, lineIndex) => (
                                        <p key={lineIndex}>{line}</p>
                                    ))}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}