interface Article {
  title: string;
  content?: string;
  author: string;
  date: Date;
  view: number;
  id: number;
}

const notifications: Article[] = [
  {
    id: 124,
    title: "2024년 추석 연휴기간 '참배 간소화' 시행 안내",
    author: "하늘공원",
    date: new Date(),
    view: 12221,
  },
];

const articles: Article[] = [
  {
    id: 124,
    title: "2024년 추석 연휴기간 '참배 간소화' 시행 안내",
    content: `- 기간 : 2024. 9. 14(토) ~ 9. 18.(수), 5일간
 
- 대상 : 추모의집 및 자연장지
 
- 주요내용
· 실내·외 제례실 임시폐쇄 (음식물 반입 금지)
· 휴게공간에서의 음식 섭취 제한
 
※ 운영관련 전화 문의 : 052-255-3800`,
    author: "하늘공원",
    date: new Date(),
    view: 12221,
  },
  {
    id: 123,
    title: "故문강자님의 하늘장례 일정 안내",
    content: "Content for article 123",
    author: "하늘공원",
    date: new Date(),
    view: 12120,
  },
  {
    id: 122,
    title: "울산하늘공원 추모의집 추가 봉안실 조성공사 안내",
    content: "Content for article 122",
    author: "하늘공원",
    date: new Date(),
    view: 12019,
  },
  {
    id: 121,
    title: '추모공연 "하늘 음악회" 행사 안내',
    content: "Content for article 121",
    author: "하늘공원",
    date: new Date(),
    view: 11918,
  },
  {
    id: 120,
    title: "2024년 청명·한식 개장유골 화장 추가 확대운영 안내",
    content: "Content for article 120",
    author: "하늘공원",
    date: new Date(),
    view: 11817,
  },
  {
    id: 119,
    title: "2024년 청명·한식 개장유골 화장 추가 확대운영 안내",
    content: "Content for article 119",
    author: "하늘공원",
    date: new Date(),
    view: 11716,
  },
  {
    id: 118,
    title: "2024년 설 연휴기간 '참배 간소화' 시행 안내",
    content: "Content for article 118",
    author: "하늘공원",
    date: new Date(),
    view: 11615,
  },
  {
    id: 117,
    title: "울산하늘공원 '모바일 사전 화장접수' 시범운영 알림",
    content: "Content for article 117",
    author: "하늘공원",
    date: new Date(),
    view: 11514,
  },
  {
    id: 116,
    title: "2023년 추석 연휴기간 '참배 간소화' 시행 안내",
    content: "Content for article 116",
    author: "하늘공원",
    date: new Date(),
    view: 11413,
  },
  {
    id: 115,
    title: "울산하늘공원 시설 보수공사 관련 이용 알림",
    content: "Content for article 115",
    author: "하늘공원",
    date: new Date(),
    view: 11312,
  },
  {
    id: 114,
    title: "Title 114",
    content: "Content for article 114",
    author: "하늘공원",
    date: new Date(),
    view: 11211,
  },
  {
    id: 113,
    title: "Title 113",
    content: "Content for article 113",
    author: "하늘공원",
    date: new Date(),
    view: 11110,
  },
  {
    id: 112,
    title: "Title 112",
    content: "Content for article 112",
    author: "하늘공원",
    date: new Date(),
    view: 11009,
  },
  {
    id: 111,
    title: "Title 111",
    content: "Content for article 111",
    author: "하늘공원",
    date: new Date(),
    view: 10908,
  },
  {
    id: 110,
    title: "Title 110",
    content: "Content for article 110",
    author: "하늘공원",
    date: new Date(),
    view: 10807,
  },
  {
    id: 109,
    title: "Title 109",
    content: "Content for article 109",
    author: "하늘공원",
    date: new Date(),
    view: 10706,
  },
  {
    id: 108,
    title: "Title 108",
    content: "Content for article 108",
    author: "하늘공원",
    date: new Date(),
    view: 10605,
  },
  {
    id: 107,
    title: "Title 107",
    content: "Content for article 107",
    author: "하늘공원",
    date: new Date(),
    view: 10504,
  },
  {
    id: 106,
    title: "Title 106",
    content: "Content for article 106",
    author: "하늘공원",
    date: new Date(),
    view: 10403,
  },
  {
    id: 105,
    title: "Title 105",
    content: "Content for article 105",
    author: "하늘공원",
    date: new Date(),
    view: 10302,
  },
  {
    id: 104,
    title: "Title 104",
    content: "Content for article 104",
    author: "하늘공원",
    date: new Date(),
    view: 10201,
  },
  {
    id: 103,
    title: "Title 103",
    content: "Content for article 103",
    author: "하늘공원",
    date: new Date(),
    view: 10100,
  },
  {
    id: 102,
    title: "Title 102",
    content: "Content for article 102",
    author: "하늘공원",
    date: new Date(),
    view: 9999,
  },
  {
    id: 101,
    title: "Title 101",
    content: "Content for article 101",
    author: "하늘공원",
    date: new Date(),
    view: 9898,
  },
  {
    id: 100,
    title: "Title 100",
    content: "Content for article 100",
    author: "하늘공원",
    date: new Date(),
    view: 9797,
  },
  {
    id: 99,
    title: "Title 99",
    content: "Content for article 99",
    author: "하늘공원",
    date: new Date(),
    view: 9696,
  },
  {
    id: 98,
    title: "Title 98",
    content: "Content for article 98",
    author: "하늘공원",
    date: new Date(),
    view: 9595,
  },
  {
    id: 97,
    title: "Title 97",
    content: "Content for article 97",
    author: "하늘공원",
    date: new Date(),
    view: 9494,
  },
  {
    id: 96,
    title: "Title 96",
    content: "Content for article 96",
    author: "하늘공원",
    date: new Date(),
    view: 9393,
  },
  {
    id: 95,
    title: "Title 95",
    content: "Content for article 95",
    author: "하늘공원",
    date: new Date(),
    view: 9292,
  },
  {
    id: 94,
    title: "Title 94",
    content: "Content for article 94",
    author: "하늘공원",
    date: new Date(),
    view: 9191,
  },
  {
    id: 93,
    title: "Title 93",
    content: "Content for article 93",
    author: "하늘공원",
    date: new Date(),
    view: 9090,
  },
  {
    id: 92,
    title: "Title 92",
    content: "Content for article 92",
    author: "하늘공원",
    date: new Date(),
    view: 8989,
  },
  {
    id: 91,
    title: "Title 91",
    content: "Content for article 91",
    author: "하늘공원",
    date: new Date(),
    view: 8888,
  },
  {
    id: 90,
    title: "Title 90",
    content: "Content for article 90",
    author: "하늘공원",
    date: new Date(),
    view: 8787,
  },
  {
    id: 89,
    title: "Title 89",
    content: "Content for article 89",
    author: "하늘공원",
    date: new Date(),
    view: 8686,
  },
  {
    id: 88,
    title: "Title 88",
    content: "Content for article 88",
    author: "하늘공원",
    date: new Date(),
    view: 8585,
  },
  {
    id: 87,
    title: "Title 87",
    content: "Content for article 87",
    author: "하늘공원",
    date: new Date(),
    view: 8484,
  },
  {
    id: 86,
    title: "Title 86",
    content: "Content for article 86",
    author: "하늘공원",
    date: new Date(),
    view: 8383,
  },
  {
    id: 85,
    title: "Title 85",
    content: "Content for article 85",
    author: "하늘공원",
    date: new Date(),
    view: 8282,
  },
  {
    id: 84,
    title: "Title 84",
    content: "Content for article 84",
    author: "하늘공원",
    date: new Date(),
    view: 8181,
  },
  {
    id: 83,
    title: "Title 83",
    content: "Content for article 83",
    author: "하늘공원",
    date: new Date(),
    view: 8080,
  },
  {
    id: 82,
    title: "Title 82",
    content: "Content for article 82",
    author: "하늘공원",
    date: new Date(),
    view: 7979,
  },
  {
    id: 81,
    title: "Title 81",
    content: "Content for article 81",
    author: "하늘공원",
    date: new Date(),
    view: 7878,
  },
  {
    id: 80,
    title: "Title 80",
    content: "Content for article 80",
    author: "하늘공원",
    date: new Date(),
    view: 7777,
  },
  {
    id: 79,
    title: "Title 79",
    content: "Content for article 79",
    author: "하늘공원",
    date: new Date(),
    view: 7676,
  },
  {
    id: 78,
    title: "Title 78",
    content: "Content for article 78",
    author: "하늘공원",
    date: new Date(),
    view: 7575,
  },
  {
    id: 77,
    title: "Title 77",
    content: "Content for article 77",
    author: "하늘공원",
    date: new Date(),
    view: 7474,
  },
  {
    id: 76,
    title: "Title 76",
    content: "Content for article 76",
    author: "하늘공원",
    date: new Date(),
    view: 7373,
  },
  {
    id: 75,
    title: "Title 75",
    content: "Content for article 75",
    author: "하늘공원",
    date: new Date(),
    view: 7272,
  },
  {
    id: 74,
    title: "Title 74",
    content: "Content for article 74",
    author: "하늘공원",
    date: new Date(),
    view: 7171,
  },
  {
    id: 73,
    title: "Title 73",
    content: "Content for article 73",
    author: "하늘공원",
    date: new Date(),
    view: 7070,
  },
  {
    id: 72,
    title: "Title 72",
    content: "Content for article 72",
    author: "하늘공원",
    date: new Date(),
    view: 6969,
  },
  {
    id: 71,
    title: "Title 71",
    content: "Content for article 71",
    author: "하늘공원",
    date: new Date(),
    view: 6868,
  },
  {
    id: 70,
    title: "Title 70",
    content: "Content for article 70",
    author: "하늘공원",
    date: new Date(),
    view: 6767,
  },
  {
    id: 69,
    title: "Title 69",
    content: "Content for article 69",
    author: "하늘공원",
    date: new Date(),
    view: 6666,
  },
  {
    id: 68,
    title: "Title 68",
    content: "Content for article 68",
    author: "하늘공원",
    date: new Date(),
    view: 6565,
  },
  {
    id: 67,
    title: "Title 67",
    content: "Content for article 67",
    author: "하늘공원",
    date: new Date(),
    view: 6464,
  },
  {
    id: 66,
    title: "Title 66",
    content: "Content for article 66",
    author: "하늘공원",
    date: new Date(),
    view: 6363,
  },
  {
    id: 65,
    title: "Title 65",
    content: "Content for article 65",
    author: "하늘공원",
    date: new Date(),
    view: 6262,
  },
  {
    id: 64,
    title: "Title 64",
    content: "Content for article 64",
    author: "하늘공원",
    date: new Date(),
    view: 6161,
  },
  {
    id: 63,
    title: "Title 63",
    content: "Content for article 63",
    author: "하늘공원",
    date: new Date(),
    view: 6060,
  },
  {
    id: 62,
    title: "Title 62",
    content: "Content for article 62",
    author: "하늘공원",
    date: new Date(),
    view: 5959,
  },
  {
    id: 61,
    title: "Title 61",
    content: "Content for article 61",
    author: "하늘공원",
    date: new Date(),
    view: 5858,
  },
  {
    id: 60,
    title: "Title 60",
    content: "Content for article 60",
    author: "하늘공원",
    date: new Date(),
    view: 5757,
  },
  {
    id: 59,
    title: "Title 59",
    content: "Content for article 59",
    author: "하늘공원",
    date: new Date(),
    view: 5656,
  },
  {
    id: 58,
    title: "Title 58",
    content: "Content for article 58",
    author: "하늘공원",
    date: new Date(),
    view: 5555,
  },
  {
    id: 57,
    title: "Title 57",
    content: "Content for article 57",
    author: "하늘공원",
    date: new Date(),
    view: 5454,
  },
  {
    id: 56,
    title: "Title 56",
    content: "Content for article 56",
    author: "하늘공원",
    date: new Date(),
    view: 5353,
  },
  {
    id: 55,
    title: "Title 55",
    content: "Content for article 55",
    author: "하늘공원",
    date: new Date(),
    view: 5252,
  },
  {
    id: 54,
    title: "Title 54",
    content: "Content for article 54",
    author: "하늘공원",
    date: new Date(),
    view: 5151,
  },
  {
    id: 53,
    title: "Title 53",
    content: "Content for article 53",
    author: "하늘공원",
    date: new Date(),
    view: 5050,
  },
  {
    id: 52,
    title: "Title 52",
    content: "Content for article 52",
    author: "하늘공원",
    date: new Date(),
    view: 4949,
  },
  {
    id: 51,
    title: "Title 51",
    content: "Content for article 51",
    author: "하늘공원",
    date: new Date(),
    view: 4848,
  },
  {
    id: 50,
    title: "Title 50",
    content: "Content for article 50",
    author: "하늘공원",
    date: new Date(),
    view: 4747,
  },
  {
    id: 49,
    title: "Title 49",
    content: "Content for article 49",
    author: "하늘공원",
    date: new Date(),
    view: 4646,
  },
  {
    id: 48,
    title: "Title 48",
    content: "Content for article 48",
    author: "하늘공원",
    date: new Date(),
    view: 4545,
  },
  {
    id: 47,
    title: "Title 47",
    content: "Content for article 47",
    author: "하늘공원",
    date: new Date(),
    view: 4444,
  },
  {
    id: 46,
    title: "Title 46",
    content: "Content for article 46",
    author: "하늘공원",
    date: new Date(),
    view: 4343,
  },
  {
    id: 45,
    title: "Title 45",
    content: "Content for article 45",
    author: "하늘공원",
    date: new Date(),
    view: 4242,
  },
  {
    id: 44,
    title: "Title 44",
    content: "Content for article 44",
    author: "하늘공원",
    date: new Date(),
    view: 4141,
  },
  {
    id: 43,
    title: "Title 43",
    content: "Content for article 43",
    author: "하늘공원",
    date: new Date(),
    view: 4040,
  },
  {
    id: 42,
    title: "Title 42",
    content: "Content for article 42",
    author: "하늘공원",
    date: new Date(),
    view: 3939,
  },
  {
    id: 41,
    title: "Title 41",
    content: "Content for article 41",
    author: "하늘공원",
    date: new Date(),
    view: 3838,
  },
  {
    id: 40,
    title: "Title 40",
    content: "Content for article 40",
    author: "하늘공원",
    date: new Date(),
    view: 3737,
  },
  {
    id: 39,
    title: "Title 39",
    content: "Content for article 39",
    author: "하늘공원",
    date: new Date(),
    view: 3636,
  },
  {
    id: 38,
    title: "Title 38",
    content: "Content for article 38",
    author: "하늘공원",
    date: new Date(),
    view: 3535,
  },
  {
    id: 37,
    title: "Title 37",
    content: "Content for article 37",
    author: "하늘공원",
    date: new Date(),
    view: 3434,
  },
  {
    id: 36,
    title: "Title 36",
    content: "Content for article 36",
    author: "하늘공원",
    date: new Date(),
    view: 3333,
  },
  {
    id: 35,
    title: "Title 35",
    content: "Content for article 35",
    author: "하늘공원",
    date: new Date(),
    view: 3232,
  },
  {
    id: 34,
    title: "Title 34",
    content: "Content for article 34",
    author: "하늘공원",
    date: new Date(),
    view: 3131,
  },
  {
    id: 33,
    title: "Title 33",
    content: "Content for article 33",
    author: "하늘공원",
    date: new Date(),
    view: 3030,
  },
  {
    id: 32,
    title: "Title 32",
    content: "Content for article 32",
    author: "하늘공원",
    date: new Date(),
    view: 2929,
  },
  {
    id: 31,
    title: "Title 31",
    content: "Content for article 31",
    author: "하늘공원",
    date: new Date(),
    view: 2828,
  },
  {
    id: 30,
    title: "Title 30",
    content: "Content for article 30",
    author: "하늘공원",
    date: new Date(),
    view: 2727,
  },
  {
    id: 29,
    title: "Title 29",
    content: "Content for article 29",
    author: "하늘공원",
    date: new Date(),
    view: 2828,
  },
  {
    id: 28,
    title: "Title 28",
    content: "Content for article 28",
    author: "하늘공원",
    date: new Date(),
    view: 2727,
  },
  {
    id: 27,
    title: "Title 27",
    content: "Content for article 27",
    author: "하늘공원",
    date: new Date(),
    view: 2626,
  },
  {
    id: 26,
    title: "Title 26",
    content: "Content for article 26",
    author: "하늘공원",
    date: new Date(),
    view: 2525,
  },
  {
    id: 25,
    title: "Title 25",
    content: "Content for article 25",
    author: "하늘공원",
    date: new Date(),
    view: 2424,
  },
  {
    id: 24,
    title: "Title 24",
    content: "Content for article 24",
    author: "하늘공원",
    date: new Date(),
    view: 2323,
  },
  {
    id: 23,
    title: "Title 23",
    content: "Content for article 23",
    author: "하늘공원",
    date: new Date(),
    view: 2222,
  },
  {
    id: 22,
    title: "Title 22",
    content: "Content for article 22",
    author: "하늘공원",
    date: new Date(),
    view: 2121,
  },
  {
    id: 21,
    title: "Title 21",
    content: "Content for article 21",
    author: "하늘공원",
    date: new Date(),
    view: 2020,
  },
  {
    id: 20,
    title: "Title 20",
    content: "Content for article 20",
    author: "하늘공원",
    date: new Date(),
    view: 1919,
  },
  {
    id: 19,
    title: "Title 19",
    content: "Content for article 19",
    author: "하늘공원",
    date: new Date(),
    view: 1818,
  },
  {
    id: 18,
    title: "Title 18",
    content: "Content for article 18",
    author: "하늘공원",
    date: new Date(),
    view: 1717,
  },
  {
    id: 17,
    title: "Title 17",
    content: "Content for article 17",
    author: "하늘공원",
    date: new Date(),
    view: 1616,
  },
  {
    id: 16,
    title: "Title 16",
    content: "Content for article 16",
    author: "하늘공원",
    date: new Date(),
    view: 1515,
  },
  {
    id: 15,
    title: "Title 15",
    content: "Content for article 15",
    author: "하늘공원",
    date: new Date(),
    view: 1414,
  },
  {
    id: 14,
    title: "Title 14",
    content: "Content for article 14",
    author: "하늘공원",
    date: new Date(),
    view: 1313,
  },
  {
    id: 13,
    title: "Title 13",
    content: "Content for article 13",
    author: "하늘공원",
    date: new Date(),
    view: 1212,
  },
  {
    id: 12,
    title: "Title 12",
    content: "Content for article 12",
    author: "하늘공원",
    date: new Date(),
    view: 1111,
  },
  {
    id: 11,
    title: "Title 11",
    content: "Content for article 11",
    author: "하늘공원",
    date: new Date(),
    view: 1010,
  },
  {
    id: 10,
    title: "Title 10",
    content: "Content for article 10",
    author: "하늘공원",
    date: new Date(),
    view: 999,
  },
  {
    id: 9,
    title: "Title 9",
    content: "Content for article 9",
    author: "하늘공원",
    date: new Date(),
    view: 898,
  },
  {
    id: 8,
    title: "Title 8",
    content: "Content for article 8",
    author: "하늘공원",
    date: new Date(),
    view: 797,
  },
  {
    id: 7,
    title: "Title 7",
    content: "Content for article 7",
    author: "하늘공원",
    date: new Date(),
    view: 696,
  },
  {
    id: 6,
    title: "Title 6",
    content: "Content for article 6",
    author: "하늘공원",
    date: new Date(),
    view: 595,
  },
  {
    id: 5,
    title: "Title 5",
    content: "Content for article 5",
    author: "하늘공원",
    date: new Date(),
    view: 494,
  },
  {
    id: 4,
    title: "Title 4",
    content: "Content for article 4",
    author: "하늘공원",
    date: new Date(),
    view: 393,
  },
  {
    id: 3,
    title: "Title 3",
    content: "Content for article 3",
    author: "하늘공원",
    date: new Date(),
    view: 292,
  },
  {
    id: 2,
    title: "Title 2",
    content: "Content for article 2",
    author: "하늘공원",
    date: new Date(),
    view: 191,
  },
  {
    id: 1,
    title: "Title 1",
    content: "Content for article 1",
    author: "하늘공원",
    date: new Date(),
    view: 90,
  },
];

const GetArticles = (page: number): Article[] => {
  return articles.slice((page - 1) * 10, page * 10);
};

const GetArticleCount = (): number => {
  return articles.length;
};

const GetNotification = (): Article[] => {
  return notifications;
};

const GetArticle = (id: number): Article | undefined => {
  return articles.find((article) => article.id === id);
};

export { GetArticles, GetArticleCount, GetNotification, GetArticle };
export type { Article };
