import styles from "./MemorialParkPage.module.scss";
import ParkImage from "./img/park.png";

const MemorialParkPage = () => {
  return (
    <div className={styles.container}>
      <div className={styles.callout}>
        <p>
          유택동산 운영시간{" "}
          <span className={styles.timeText}>(09:00~18:00)</span> /{" "}
          <br className={styles.MBbr} />
          접수시간 <span className={styles.timeText}>(07:00 ~ 16:00)</span>
        </p>
      </div>

      <div className={styles.photoExplain}>
        <img src={ParkImage} alt="사진" />
        <div className={styles.explain}>
          <h4>유택동산</h4>
          <ul>
            <li>유택동산: 화장된 유골을 집단으로 안장하는 시설입니다.</li>
            <li>
              산골장: 유택동산에 안장된 유골을, 집단으로 산골하는 장소입니다.
            </li>
          </ul>
        </div>
      </div>

      <div className={styles.paragraph}>
        <h3 className={styles.subheading}>사용대상</h3>
        <ul>
          <li>
            유택동산은 누구든지 사용할 수 있으며, 유골은 반출되지 않습니다.
          </li>
          <li>
            유족은 <span className={styles.red}>유골포기각서를 제출</span>해야
            합니다.
          </li>
        </ul>
      </div>

      <div className={styles.paragraph}>
        <h3 className={styles.subheading}>산골절차</h3>
        <section className={styles.table_container}>
          <table className={styles.rowTable}>
            <tr>
              <td>1. 방문하여 작성할 서류</td>
              <td>화장장에서 화장 신청시 유택동산 사용신고서 작성</td>
            </tr>
          </table>
          <table className={styles.rowTable}>
            <tr>
              <td>2. 유택동산 이동</td>
              <td>
                화장장(수골실)에서 수습한 유골을 인수하신후, 유골을 모시고
                유택동산으로 이동
              </td>
            </tr>
          </table>
          <table className={styles.rowTable}>
            <tr>
              <td>3. 합동유골처리장</td>
              <td>
                제단 위에 원형 덮개를 열고 고인의 유골을 투입후 안으로 산골
              </td>
            </tr>
          </table>
        </section>
      </div>

      <div className={styles.paragraph}>
        <h3 className={styles.subheading}>구비서류</h3>
        <section className={styles.table_container}>
          <table className={styles.defaultTable}>
            <thead>
              <tr>
                <th>방문하여 작성할 서류</th>
                <th>사용자 필수 지참 서류</th>
                <th>울산하늘공원 확인 가능 서류</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div>
                    <ul>
                      <li>울산하늘공원 사용 허가 신청서</li>
                      <li>유골 인도 신청서</li>
                    </ul>
                  </div>
                </td>
                <td>
                  <div>
                    <ul>
                      <li>제적증명서</li>
                      <li>화장신고증명서</li>
                      <li>타시설사용증명서</li>
                    </ul>
                  </div>
                </td>
                <td>
                  <div>
                    <ul>
                      <li>주민등록등본(고인)</li>
                      <li>국가유공자증명</li>
                      <li>국민기초생활수급증명</li>
                    </ul>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </section>
        <p className={styles.caution} style={{ color: "red" }}>
          시설사용 신청인은 신분증을 지참하셔야 합니다.
        </p>
      </div>

      <div className={styles.paragraph}>
        <h3 className={styles.subheading}>시설 사용료</h3>
        <section className={styles.table_container}>
          <table className={styles.defaultTable}>
            <thead>
              <tr>
                <th>구분</th>
                <th>관내주민</th>
                <th>관외주민</th>
                <th>비고</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>유택동산</td>
                <td>10,000</td>
                <td>10,000</td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </section>
        <p className={styles.caution}>
          관내주민이란 사망 당시에 주민등록이 울산광역시에 되어 있는 자를
          말합니다.
        </p>
      </div>

      <div className={styles.paragraph}>
        <h3 className={styles.subheading}>사용료 면제(감면 적용 사용료)</h3>
        <ul style={{ rowGap: "10px", marginBottom: "20px" }}>
          <li>「국민기초생활보장법」에 따른 생계·의료급여 수급자</li>
          <li>「국가보훈기본법」에 따른 희생/공헌자와 그 배우자</li>
        </ul>
        <section className={styles.table_container}>
          <table className={styles.defaultTable}>
            <thead>
              <tr>
                <th style={{ width: "236px" }}>구분</th>
                <th>삼동주민(80% 감액)</th>
                <th>비고</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>유택동산</td>
                <td>2,000</td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </section>
      </div>
    </div>
  );
};

export default MemorialParkPage;
