import { useNavigate } from "react-router-dom";
import styles from "./NoticePage.module.scss";
import { useEffect, useState } from "react";
import {
  GetArticles,
  GetArticleCount,
  GetNotification,
  Article,
} from "./ariticleData";

function formatDate(date: Date): string {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
}

const NoticePage = () => {
  const [aritcleLength, setArticleLength] = useState<number>(0);
  const [articles, setArticles] = useState<Article[]>([]);
  const [notification, setNotification] = useState<Article[]>();
  const [curIndex, setcurIndex] = useState<number>(0);
  const [tableIndex, setTableIndex] = useState<number>(0);

  useEffect(() => {
    setArticleLength(GetArticleCount());
    setNotification(GetNotification());
  }, []);

  useEffect(() => {
    setArticles(GetArticles(curIndex + 1));
  }, [curIndex]);

  const navigate = useNavigate();

  return (
    <div className={styles.container}>
      <div className={styles.controls}>
        <p className={styles.pageLength}>
          전체&nbsp;<span>{aritcleLength}</span>건의 게시물이 있습니다.
        </p>
        <form className={styles.search}>
          <select>
            <option value="title">제목</option>
            <option value="content">내용</option>
            <option value="author">작성자</option>
          </select>
          <input type="text" placeholder="검색어를 입력하세요" />
          <button type="submit"></button>
        </form>
      </div>

      <table className={styles.articleTable}>
        <thead>
          <tr>
            <th>번호</th>
            <th>제목</th>
            <th>작성자</th>
            <th>작성일</th>
            <th>조회수</th>
          </tr>
        </thead>
        <tbody>
          {notification &&
            notification.map((article, index) => (
              <tr key={index}>
                <td>
                  <span className={styles.notificationLabel}>공지</span>
                </td>
                <td>
                  <a
                    onClick={() => {
                      navigate("/notices/" + article.id.toString());
                    }}
                  >
                    {article.title}
                  </a>
                </td>
                <td>{article.author}</td>
                <td>{formatDate(article.date)}</td>
                <td>{article.view}</td>
              </tr>
            ))}
          {articles &&
            articles.map((article, index) => (
              <tr key={index}>
                <td>{article.id}</td>
                <td>
                  <a
                    onClick={() => {
                      navigate("/notices/" + article.id.toString());
                    }}
                  >
                    {article.title}
                  </a>
                </td>
                <td>{article.author}</td>
                <td>{formatDate(article.date)}</td>
                <td>{article.view}</td>
              </tr>
            ))}
        </tbody>
      </table>

      <div className={styles.pagination}>
        <button
          onClick={() => {
            if (tableIndex > 0) {
              setTableIndex(tableIndex - 5);
              setcurIndex(tableIndex - 5);
            }
          }}
        ></button>
        {[...Array(Math.ceil(aritcleLength / 10))]
          .slice(tableIndex, tableIndex + 5)
          .map((_, index) => (
            <label
              htmlFor={`page${index + tableIndex + 1}`}
              key={index + tableIndex}
            >
              {index + tableIndex + 1}
              <input
                type="radio"
                key={index + tableIndex}
                name="page"
                id={`page${index + tableIndex + 1}`}
                value={index + tableIndex + 1}
                defaultChecked={index === 0}
                onClick={() => {
                  setcurIndex(index + tableIndex);
                }}
              ></input>
            </label>
          ))}
        <button
          onClick={() => {
            if (tableIndex + 5 < Math.ceil(aritcleLength / 5)) {
              setTableIndex(tableIndex + 5);
              setcurIndex(tableIndex + 5);
            }
          }}
        ></button>
      </div>
    </div>
  );
};

export default NoticePage;
