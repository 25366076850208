interface SubMenu {
  title?: { name: string; url: string } | null;
  items?: { name: string; url: string }[];
}

export interface MenuItem {
  title?: string;
  subMenus: SubMenu[];
}

//* 장례식장 소개
export const sideIntroData: MenuItem = {
  title: "장례식장 소개",
  subMenus: [
    {
      title: null, //{name:'소개', url:'/introduction/'},
      items: [
        { name: "경영이념", url: "/introduction/philosophy" },
        { name: "인사말", url: "/introduction/greeting" },
        { name: "오시는길", url: "/introduction/directions" },
      ],
    },
  ],
};

// * 시설현황
export const sideFacilitiesData: MenuItem = {
  title: "시설 현황",
  subMenus: [
    {
      title: { name: "1층", url: "/facilities/1st" },
      items: [
        { name: "장례사무실", url: "/facilities/1st/funeral-office" },
        { name: "입관실, 안치실", url: "/facilities/1st/preparation-room" },
        { name: "사무국", url: "/facilities/1st/office" },
        {
          name: "봉안함, 위패 접수 및 정산실",
          url: "/facilities/1st/reception",
        },
        { name: "가족 대기실", url: "/facilities/1st/family-waiting-room" },
      ],
    },
    {
      title: { name: "2층", url: "/facilities/2nd" },
      items: [
        { name: "빈소", url: "/facilities/2nd/mourning-room" },
        { name: "가족 휴게실", url: "/facilities/2nd/family-lounge" },
        { name: "식당, 매점", url: "/facilities/2nd/restaurant" },
        { name: "카페", url: "/facilities/2nd/cafe" },
      ],
    },
  ],
};

// * 이용안내
export const sideUsageData: MenuItem = {
  title: "이용안내",
  subMenus: [
    {
      title: { name: "장례식장 이용안내", url: "/usage/funeral" }, // 추가
      items: [
        { name: "장례용품", url: "/usage/funeral/funeral-items" },
        { name: "빈소, 오브제(화환)", url: "/usage/funeral/funeral-hall" },
        {
          name: "봉안함, 위패, 표지석 각인",
          url: "/usage/funeral/memorial-engraving",
        },
        {
          name: "제례상(49상, 기제사)",
          url: "/usage/funeral/memorial-ceremony",
        },
        {
          name: "식당, 매점(꽃), 카페",
          url: "/usage/funeral/dining-facilities",
        },
      ],
    },
    {
      title: { name: "부대시설 이용안내", url: "/usage/facilities" }, // 추가
      items: [
        { name: "승화원", url: "/usage/facilities/crematorium" },
        {
          name: "자연장지(수목장, 잔디장)",
          url: "/usage/facilities/natural-burial",
        },
        { name: "추모의 집", url: "/usage/facilities/memorial-house" },
        { name: "유택동산", url: "/usage/facilities/memorial-park" },
      ],
    },
  ],
};

export const sideProcedureData: MenuItem = {
  title: "장례안내",
  subMenus: [
    {
      title: { name: "장례절차", url: "/guide/procedure" },
      items: [
        { name: "임종 전", url: "/guide/procedure/before-death" },
        { name: "임종 후", url: "/guide/procedure/after-death" },
        { name: "장례 후", url: "/guide/procedure/after-funeral" },
      ],
    },
    {
      title: { name: "장례 정보", url: "/guide" },
      items: [
        { name: "예법", url: "/guide/info" },
        { name: "제례", url: "/guide/info/ancestral-rites" },
      ],
    },
  ],
};

export const sideNoticesData: MenuItem = {
  title: "공지사항",
  subMenus: [
    {
      title: null,
      items: [{ name: "공지사항", url: "/notices" }],
    },
  ],
};

export const sideSiteMapData: MenuItem = {
  title: "사이트맵",
  subMenus: [
    {
      title: null,
      items: [{ name: "사이트맵", url: "/sitemap" }],
    },
  ],
};
