import styles from './AfterFuneral.module.scss';

export default function AfterFuneral(){
    return (
        <main className={styles.container}>
            <section className={styles.certificateDeath}>
                <h3 className={styles.subheading2}>사망진단서 제출</h3>
                <div className={styles.tableDiv}>
                    <table>
                        <thead>
                            <th>구분</th>
                            <th>용도</th>
                            <th>신고서류</th>
                            <th>비고</th>
                        </thead>
                        <tbody>
                            <tr>
                                <td className={styles.medium}>동 주민센터</td>
                                <td>사망신고서</td>
                                <td>
                                    <div className={styles.documentDiv}>
                                        <li>사망증명서류1부</li>
                                        <li>고인 주민등록증</li>
                                        <li>신고자 도장</li>
                                    </div>
                                </td>
                                <td>1개월 이내 신고</td>
                            </tr>
                            <tr>
                                <td className={styles.medium}>기타 보험청구</td>
                                <td>보험청구시</td>
                                <td>사망증명서류1부</td>
                                <td>필요시</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </section>

            <section className={styles.afterDeathFollowUp}>
                <h3 className={styles.subheading3}>장례절차이후 사망신고(사망일로부터 30일이내) 후속조치 사항</h3>

                <div className={styles.tableDiv}>
                    <table>
                        <thead>
                            <tr>
                                <th><p className={styles.font}></p>구분</th>
                                <th>조치 사항</th>
                                <th>신고(신청)기관</th>
                                <th>관계기관</th>
                                <th>접수·처리기관</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className={styles.categoryTd} rowSpan={2}>1. 사망자 재산 조회</td>
                                <td>사망자 금융거래 조회</td>
                                <td rowSpan={2}></td>
                                <td>금융감독원</td>
                                <td>금융감독원</td>
                            </tr>
                            <tr>
                                <td>사망자 토지 소유 조회</td>
                                <td>국토지리정보원</td>
                                <td>국토지리정보원</td>
                            </tr>
                            <tr>
                                <td className={styles.categoryTd} rowSpan={6}>2. 사망자 재산 상속</td>
                                <td>상속으로 인한 소유권 이전 등기</td>
                                <td rowSpan={4}></td>
                                <td rowSpan={4}>지방법원 등기과(소)</td>
                                <td rowSpan={4}>대법원</td>
                            </tr>
                            <tr>
                                <td>협의분할에 의한 상속으로 인한 소유권 이전등기</td>
                            </tr>
                            <tr>
                                <td>협의분할로 인한 상속에 의한 소유권 경정등기</td>
                            </tr>
                            <tr>
                                <td>유증으로 인한 소유권 이전등기</td>
                            </tr>
                            <tr>
                                <td>재산상속 한정승인, 포기</td>
                                <td>상속개시를 안 날부터 3월</td>
                                <td>피상속인의 최후<br/> 주소지 관할 가정법원</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>자동차 소유권 이전등록</td>
                                <td>상속개시일로부터 3월</td>
                                <td>자동차등록관청</td>
                                <td></td>
                            </tr>
                            {/* 3번 */}
                            <tr>
                                <td className={styles.categoryTd} rowSpan={2}>3. 상속에 따른 세금 납부</td>
                                <td>취득세, 등록세 신고 납부</td>
                                <td>상속개시일로부터 6월</td>
                                <td>물건지 관할 시·군·구</td>
                                <td>행정안정부</td>
                            </tr>
                            <tr>
                                <td>상속세 신고 납부</td>
                                <td>상속개시일로부터 6월</td>
                                <td>피상속인의 주소지<br/> 관할 세무서</td>
                                <td>국세청</td>
                            </tr>
                            <tr>
                                <td className={styles.categoryTd}>4. 국민연금 청구</td>
                                <td>국민연금(유족연금, 반환일시금, 사망일시금)청구</td>
                                <td>지급사유 발생일로부터 5년</td>
                                <td>국민연금관리공단</td>
                                <td>국민연금관리공단</td>
                            </tr>
                            {/* 5번 */}
                            <tr>
                                <td className={styles.categoryTd} rowSpan={3}>5. 우체국예금·보험청구</td>
                                <td>상속예금 지급청구</td>
                                <td rowSpan={3}>상속개시일로부터 3일</td>
                                <td rowSpan={3}>우체국</td>
                                <td rowSpan={3}>우정사업본부</td>
                            </tr>
                            <tr>
                                <td>상속예금 승계신고</td>
                            </tr>
                            <tr>
                                <td>대인보험 지급청구</td>
                            </tr>
                            <tr>
                                <td className={styles.categoryTd} rowSpan={3}>6. 영업자 지위 승계</td>
                                <td>공중위생영업 영업자 지위승계신고</td>
                                <td rowSpan={2}>사망일로부터 1월</td>
                                <td rowSpan={2}>허가(신고)청</td>
                                <td rowSpan={2}>보건복지부</td>
                            </tr>
                            <tr>
                                <td>식품영업 영업자 지위승계신고</td>
                            </tr>
                            <tr>
                                <td>주요 지위승계 신고사항</td>
                                <td>사망일로부터 15일~6월</td>
                                <td>허가(등록,신고)청</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td className={styles.categoryTd} rowSpan={2}>7. 기타 후속조치 사항</td>
                                <td>사업자등록정정신고</td>
                                <td>지체없이</td>
                                <td>세무서</td>
                                <td>국세청</td>
                            </tr>
                            <tr>
                                <td>신용카드, 휴대전화 해지 등</td>
                                <td></td>
                                <td>해당업체</td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </section>
        </main>
    )
}