import FuneralItems from "./FuneralItems";

// 테이블 표
export interface FuneralItem {
    category? : string | null; // 구분
    id?: number; // 순번
    name?: string; // 장례용품명
    standard?: string; // 규격 
    unit : string; // 단위
    price : string; // 가격
}

//아이템을 나타낼 표 모음
export interface FuneralItems {
    title: string;
    item: FuneralItem[];
}

export const FuneralData: FuneralItems[] = [
    {
        title: '관',
        item: [
            {
                id: 1, // 순번
                name: '1.0 2단 오동관', // 장례용품명
                standard: '1850x480x380x30', // 규격 
                unit: '개', // 단위
                price: '180,000' // 가격
            },
            {
                id: 2,
                name: '1.0 2단오동특관',
                standard: '1950x510x450x30',
                unit: '개',
                price: '230,000'
            },
            {
                id: 3,
                name: '1.0 2단오동특특관',
                standard: '2050x540x440x30',
                unit: '개',
                price: '260,000'
            },
            {
                id: 4,
                name: '1.5 2단오동관',
                standard: '1850x510x410x45',
                unit: '개',
                price: '244,000'
            },
            {
                id: 5,
                name: '1.5 2단오동특관',
                standard: '1980x540x450x45',
                unit: '개',
                price: '276,000'
            },
            {
                id: 6,
                name: '1.5 2단오동통판',
                standard: '1950x510x450x45',
                unit: '개',
                price: '299,000'
            },
            {
                id: 7,
                name: '1.5 2단솔송관',
                standard: '1950x510x450x45',
                unit: '개',
                price: '650,000'
            },
            {
                id: 8,
                name: '1.5 2단 향나무관',
                standard: '1950x510x450x45',
                unit: '개',
                price: '1,057,000'
            },
            {
                id: 9,
                name: '0.6 집성판/애기관',
                standard: '',
                unit: '개',
                price: '70,000'
            },
            {
                id: 10,
                name: '종이관',
                standard: '1850x480x380x30',
                unit: '개',
                price: '180,000'
            },
            {
                id: 11,
                name: '종이특관',
                standard: '1950x510x410x30',
                unit: '개',
                price: '210,000'
            },
        ]
    },    
    {
        title: '수의',
        item: [
            {
                id: 1,
                name: '하늘1호',
                standard: '육수(면30수+폴리70% 홑밥)100%,\n갖은수의 : 중국/중국',
                unit: '벌',
                price: '110,000'
            },
            {
                id: 2,
                name: '하늘2호',
                standard: '폴리100%, 육수(면30수+폴리70% 홑밥)100%,\n갖은수의 : 중국/중국',
                unit: '벌',
                price: '290,000'
            },
            {
                id: 3,
                name: '하늘3호',
                standard: '면100%, 갖은수의 : 중국/중국',
                unit: '벌',
                price: '200,000'
            },
            {
                id: 4,
                name: '하늘4호',
                standard: '저마100%',
                unit: '벌',
                price: '292,000'
            },
            {
                id: 5,
                name: '하늘5호',
                standard: '레이온(인견)100%, 저마(모시)100%, \n갖은수의 : 중국/중국',
                unit: '벌',
                price: '300,000'
            },
            {
                id: 6,
                name: '하늘6호',
                standard: '레이온(인견)100%, 저마(모시)100%, \n갖은수의 : 중국/중국',
                unit: '벌',
                price: '370,000'
            },
            {
                id: 7,
                name: '하늘7호',
                standard: '견(명주)100%, 갖은수의 : 중국/한국',
                unit: '벌',
                price: '471,000'
            },
            {
                id: 8,
                name: '하늘8호',
                standard: '견(명주)100%, 갖은수의 : 중국/한국',
                unit: '벌',
                price: '700,000'
            },
            {
                id: 9,
                name: '하늘9호',
                standard: '대마(삼베)100%, 직물등급 남해표 이상, \n갖은수의 : 중국/한국',
                unit: '벌',
                price: '750,000'
            },
            {
                id: 10,
                name: '하늘10호',
                standard: '대마(삼베)100%, 직물등급 안동포 이상,\n 갖은수의 : 한국/한국',
                unit: '벌',
                price: '1,300,000'
            },
            {
                id: 11,
                name: '하늘11호',
                standard: '펠프 100%',
                unit: '벌',
                price: '250,000'
            },
            {
                id: 12,
                name: '하늘12호',
                standard: '펠프(무지한지) 100%',
                unit: '벌',
                price: '350,000'
            },
            {
                id: 13,
                name: '하늘13호',
                standard: '펠프(무지한지) 100%',
                unit: '벌',
                price: '700,000'
            }
        ]
    },
    {
        title: '고인 용품',
        item: [
            {
                id: 1,
                name: '관보',
                standard: '레이온100% : 중국/한국',
                unit: '개',
                price: '16,000'
            },
            {
                id: 2,
                name: '명정(본견)',
                standard: '면100% : 중국/한국',
                unit: '개',
                price: '40,000'
            },
            {
                id: 3,
                name: '명정(인견)',
                standard: '레이온100% : 중국/한국',
                unit: '개',
                price: '22,000'
            },
            {
                id: 4,
                name: '보공세트',
                standard: '초석 : 중국',
                unit: '세트',
                price: '17,000'
            },
            {
                id: 5,
                name: '보공세트',
                standard: '휴지(50)',
                unit: '세트',
                price: '15,000'
            },
            {
                id: 6,
                name: '패백',
                standard: '종이 : 한국',
                unit: '개',
                price: '2,000'
            },
            {
                id: 7,
                name: '운아',
                standard: '종이 : 한국',
                unit: '개',
                price: '1,000'
            },
            {
                id: 8,
                name: '결관밥',
                standard: '폴리100% : 중국/한국',
                unit: '개',
                price: '32,000'
            },
            {
                id: 9,
                name: '소창',
                standard: '폴리100% : 중국/한국',
                unit: '개',
                price: '15,000'
            },
            {
                id: 10,
                name: '염베(상)',
                standard: '대마100% : 중국/한국',
                unit: '필',
                price: '55,000'
            },
            {
                id: 11,
                name: '염베(하)',
                standard: '대마100% : 중국/한국',
                unit: '필',
                price: '11,000'
            },
            {
                id: 12,
                name: '지매',
                standard: '한지 : 한국',
                unit: '세트',
                price: '7,000'
            },
            {
                id: 13,
                name: '한지',
                standard: '펄프 : 중국/한국(10장)',
                unit: '세트',
                price: '5,000'
            },
            {
                id: 14,
                name: '입관포',
                standard: '폴리100% : 한국/기계직',
                unit: '개',
                price: '6,000'
            },
            {
                id: 15,
                name: '다라니경',
                standard: '종이 : 한국',
                unit: '장',
                price: '1,000'
            },
            {
                id: 16,
                name: '습신',
                standard: '면30%+폴리70%: 한국/기계직',
                unit: '개',
                price: '3,000'
            },
            {
                id: 17,
                name: '세정액',
                standard: '의료용 소독액(1,000ml): 한국',
                unit: '개',
                price: '4,000'
            },
            {
                id: 18,
                name: '탈지면',
                standard: '목화: 중국/한국',
                unit: '개',
                price: '8,000'
            },
            {
                id: 19,
                name: '안치포',
                standard: '한국',
                unit: '개',
                price: '18,000'
            },
            {
                id: 20,
                name: '면모세트',
                standard: '댓님끈, 두건, 면모, 악수, 요대',
                unit: '개',
                price: '17,000'
            },
            {
                id: 21,
                name: '공포',
                standard: '면30%+폴리70%: 중국/한국',
                unit: '개',
                price: '5,000'
            },
            {
                id: 22,
                name: '칠성판',
                standard: '오동나무: 중국/한국',
                unit: '개',
                price: '11,000'
            },
            {
                id: 23,
                name: '천금/지금',
                standard: '중국/한국',
                unit: '개',
                price: '20,000'
            },
            {
                id: 24,
                name: '띠요철',
                standard: '중국/한국',
                unit: '개',
                price: '1,000'
            },
            {
                id: 25,
                name: '향균시트',
                standard: '중국/한국',
                unit: '개',
                price: '50,000'
            },
            {
                id: 26,
                name: '베개',
                standard: '면30%+폴리70%: 중국/한국',
                unit: '개',
                price: '2,000'
            },
            {
                id: 27,
                name: '면기저귀',
                standard: '면100%: 중국/한국',
                unit: '개',
                price: '7,000'
            },
            {
                id: 28,
                name: '위생용품세트',
                standard: '',
                unit: '세트',
                price: '88,000'
            }
        ]
    },
    {
        title: '상주 용품', // 이미지에서 추출된 제목
        item: [
            { id: 1, name: '골건제복', standard: '폴리70% + 면30%: 중국/한국', unit: '벌', price: '15,000' },
            { id: 2, name: '복조끼', standard: '폴리70% + 면30% : 중국/한국', unit: '개', price: '3,500' },
            { id: 3, name: '베치마', standard: '폴리70% + 면30%: 중국/한국', unit: '벌', price: '10,000' },
            { id: 4, name: '복두루마기', standard: '폴리70% + 면30% : 중국/한국', unit: '개', price: '10,000' },
            { id: 5, name: '두루마기', standard: '폴리70% + 면30% : 중국/한국', unit: '개', price: '9,000' },
            { id: 6, name: '바지저고리', standard: '폴리70% + 면30%: 중국/한국', unit: '개', price: '10,000' },
            { id: 7, name: '연두루마기(소아)', standard: '폴리70% + 면30%: 중국/한국', unit: '개', price: '7,000' },
            { id: 8, name: '치마저고리', standard: '폴리70% + 면30% : 중국/한국', unit: '벌', price: '8,000' },
            { id: 9, name: '치마저고리(소아)', standard: '폴리70% + 면30% : 중국/한국', unit: '벌', price: '7,000' },
            { id: 10, name: '두건', standard: '폴리70% + 면30% : 중국/한국', unit: '개', price: '1,000' },
            { id: 11, name: '행전', standard: '폴리70% + 면30%: 중국/한국', unit: '개', price: '1,000' },
            { id: 12, name: '요질(새끼)', standard: '폴리70% + 면30% : 중국/한국', unit: '개', price: '1,000' },
            { id: 13, name: '수질(스카프)', standard: '폴리70% + 면30% : 중국/한국', unit: '개', price: '1,000' },
            { id: 14, name: '머리수건', standard: '폴리100%: 중국/한국', unit: '개', price: '1,000' },
            { id: 15, name: '가슴리본', standard: '3입, 폴리100% : 한국/기계직', unit: '개', price: '1,000' },
            { id: 16, name: '근조리본', standard: '5일, 폴리100% : 한국/기계직', unit: '개', price: '1,000' },
            { id: 17, name: '상장(竹)', standard: '대나무', unit: '개', price: '1,000' },
            { id: 18, name: '상장(木)', standard: '오동나무', unit: '개', price: '1,000' },
            { id: 19, name: '완장(두출, 한줄)', standard: '폴리70% + 면30% : 중국/한국', unit: '개', price: '1,000' }
        ]
    },
    {
        title: '기타', // 이미지에서 추출된 제목
        item: [
            { id: 1, name: '만년향', standard: '1등급(1개입): 중국', unit: '개', price: '6,000' },
            { id: 2, name: '영정사진리본', standard: '폴리100%: 한국/기계직', unit: '개', price: '2,000' },
            { id: 3, name: '혼백함', standard: '종이+실: 한국', unit: '개', price: '2,000' },
            { id: 4, name: '축문', standard: '종이: 한국', unit: '개', price: '1,000' },
            { id: 5, name: '양초', standard: '한국', unit: '개', price: '4,000' },
            { id: 6, name: '우단부의록', standard: '우단: 한국', unit: '권', price: '8,000' },
            { id: 7, name: '종이부의록', standard: '우단+종이: 한국', unit: '권', price: '2,000' },
            { id: 8, name: '종교명패', standard: '목재', unit: '개', price: '15,000' },
            { id: 9, name: '선두차리본', standard: '중국', unit: '개', price: '5,000' },
            { id: 10, name: '위생마스크', standard: '중국', unit: '개', price: '1,000' },
            { id: 11, name: '미용글러브', standard: '중국', unit: '켤레', price: '1,000' },
            { id: 12, name: '면장갑', standard: '중국/한국', unit: '켤레', price: '1,000' }
        ]
    },
    {
        title: '현대 상복', // todo : 이것도 category때문에 따로 할지 고민.
        item: [
            { category: '대여' , id: 1, name: '남 상복(3일) 상의, 하의',  unit: '벌', price: '25,000' },
            { category: null , id: 2, name: '남 상복 (5일)', unit: '벌', price: '45,000' },
            { category: null , id: 3, name: '여 상복',  unit: '벌', price: '15,000' },
            { category: '판매' , id: 4, name: '와이셔츠',  unit: '개', price: '15,000' },
            { category: null , id: 5, name: '넥타이',  unit: '개', price: '5,000' },
            { category: null , id: 6, name: '벨트',  unit: '권', price: '9,000' }
        ]
    }
];
