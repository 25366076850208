import React from "react";
import "./Information.scss";
import { useNavigate } from "react-router-dom";

interface InfoItems {
  src: string;
  link: string;
  title: string;
  content: string[];
}

const infoItems: InfoItems[] = [
  {
    src: `${process.env.PUBLIC_URL}/informationImage/image1.png`,
    link: `tel:0522549702`,
    title: "24시간 이용 및 상담문의",
    content: ["052)264-1017"],
  },
  {
    src: `${process.env.PUBLIC_URL}/informationImage/image2.png`,
    link: `/usage/funeral/funeral-items`,
    title: "이용요금",
    content: ["울산 하늘공원 장례식장", "이용 요금을 안내해 드립니다."],
  },
  {
    src: `${process.env.PUBLIC_URL}/informationImage/image3.png`,
    link: `/introduction/directions`,
    title: "약도 및 교통",
    content: [
      "울산하늘공원 장례식장까지",
      "약도 및 교통을 확인할 수",
      "있습니다.",
    ],
  },
  {
    src: `${process.env.PUBLIC_URL}/informationImage/image4.png`,
    link: `/notices`,
    title: "공지사항",
    content: ["울산하늘공원 장례식장", "공지사항입니다."],
  },
];

const Information: React.FC = () => {
  const handleBoxClick = (link: string) => {
    window.location.href = link;
  };

  const navigate = useNavigate();

  return (
    <div className="informationSection">
      <div className="informationBoxList">
        {infoItems.map((item, index) => (
          <div
            key={index}
            className="informationBox"
            onClick={() =>
              index !== 0 ? navigate(item.link) : handleBoxClick(item.link)
            }
            style={{ cursor: "pointer" }}
          >
            <p className="informationTitle">{item.title}</p>
            <div style={{ width: "100%" }}>
              {item.content.map((content, idx) => (
                <p key={idx} className="informationContent">
                  {content}
                </p>
              ))}
            </div>
            <img
              className="informationImage"
              src={item.src}
              alt="Information"
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Information;
