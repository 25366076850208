import { useEffect, useState } from "react";
import inscription from "./imgs/inscription.png";
import procedure from "./imgs/procedure.png";
import circle1 from "./imgs/circle1.png";
import circle2 from "./imgs/circle2.png";
import square1 from "./imgs/square1.png";
import square2 from "./imgs/square2.png";
import name from "./imgs/name.png";
import plaqueback from "./imgs/plaqueback.png";
import plaqueside from "./imgs/plaqueside.png";
import plaquefront from "./imgs/plaquefront.png";
import impossible1 from "./imgs/impossible1.png";
import impossible2 from "./imgs/impossible2.png";
import impossible3 from "./imgs/impossible3.png";
import impossible4 from "./imgs/impossible4.png";
import impossible5 from "./imgs/impossible5.png";
import impossible6 from "./imgs/impossible6.png";
import mobile1 from "./imgs/Frame 327.png";
import { useMediaQuery } from "../../Introduction/Philosophy/useMediaQuery"; // 위에서 만든 훅을 가져옴
import styles from "./Inscription.module.scss";
import frame293 from "./imgs/Frame 293.png";
import image1 from "./imgs/image 115.png";
import image2 from "./imgs/image 116.png";
import image3 from "./imgs/image 117.png";
import image4 from "./imgs/Frame 294.png";

const Inscription: React.FC = () => {
  const isMobile = useMediaQuery("(max-width: 1023px)");

  return (
    <main className={styles.container}>
      <section className={styles.table_all_container}>
        <h3 className={styles.heading}>봉안함</h3>
        <img src={inscription} />
        {isMobile ? (
          <table className={styles.mobileTable}>
            <tbody>
              <tr>
                <td>봉안함</td>
                <td>자연장지함</td>
                <td>목함</td>
              </tr>
              <tr>
                <td>각인</td>
                <td>각인 소요 시간</td>
              </tr>
            </tbody>
          </table>
        ) : (
          <>
            <table>
              <thead>
                <tr>
                  <th colSpan={4}>봉안함</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td
                    style={{
                      width: "310px",
                      fontFamily: "Pretendard-Regular",
                      fontSize: "13px",
                    }}
                  >
                    자연장지함
                  </td>
                  <td style={{ width: "310px" }}>목함</td>
                  <td style={{ width: "310px" }}>각인</td>
                  <td style={{ width: "310px" }}>각인 소요 시간</td>
                </tr>
              </tbody>
            </table>
          </>
        )}
        <div className={styles.marginBot}></div>
      </section>

      <section className={styles.description_container}>
        <h3 className={styles.heading}>위패, 표지석</h3>
        <div className={styles.callout2}>문의전화: 010)9774-3303</div>
        <h3 className={styles.subheading}>진행절차</h3>
        {/* <img className={styles.image} src={isMobile ? mobile1 : procedure} /> */}
        {isMobile ? (
          <div className={styles.procedureContainer}>
            <div className={styles.box}>하늘공원(승화원)사무실 방문</div>
            <div className={styles.divider}></div>
            <div className={styles.box}>위패, 표지석 사용 허가증 발급</div>
            <div className={styles.divider}></div>
            <div className={styles.box}>위패, 표지석 접수처 접수</div>
            <img className={styles.underArrow} src={image4}></img>
            <div className={styles.box1}>
              위패
              <br />
              (개인 위패, 사진 위패)
            </div>
            <div className={styles.divider}></div>
            <div className={styles.box2}>신청 2주후 작업 완료 및 설치 </div>
            <div className={styles.divider}></div>
            <div className={styles.box3}>
              작업 완료 후, 작업자 직접 안치
              <br />
              신청자 사진전송 안내
            </div>
            <div className={styles.box4}>표지석</div>
            <div className={styles.divider}></div>
            <div className={styles.box2}>신청 10일후 작업 완료 및 설치</div>
            <div className={styles.divider}></div>
            <div className={styles.box5}>
              작업 완료 후, 작업자 표지석 직접 설치
              <br />
              안내, 사진전송X
              <br />
              10일후 신청자 직접 확인
            </div>
          </div>
        ) : (
          <div className={styles.procedureContainer}>
            <div className={styles.sebuDiv1}>
              <div className={styles.box}>하늘공원(승화원)사무실 방문</div>
              <div className={styles.divider}></div>
              <div className={styles.box}>위패, 표지석 사용 허가증 발급</div>
              <div className={styles.divider}></div>
              <div className={styles.box}>위패, 표지석 접수처 접수</div>
            </div>
            <img className={styles.arrow} src={frame293}></img>
            <div className={styles.sebuDiv2}>
              <div className={styles.rowContainer}>
                <div className={styles.box1}>
                  위패
                  <br />
                  (개인 위패, 사진 위패)
                </div>
                <div className={styles.divider2}></div>
                <div className={styles.box2}>신청 2주후 작업 완료 및 설치</div>
                <div className={styles.divider2}></div>
                <div className={styles.box3}>
                  작업 완료 후, 작업자 직접 안치
                  <br />
                  신청자 사진전송 안내
                </div>
              </div>
              <div className={styles.rowContainer}>
                <div className={styles.box4}>표지석</div>
                <div className={styles.divider2}></div>
                <div className={styles.box2}>신청 10일후 작업 완료 및 설치</div>
                <div className={styles.divider2}></div>
                <div className={styles.box5}>
                  작업 완료 후, 작업자 표지석 직접 설치
                  <br />
                  안내, 사진전송X
                  <br />
                  10일 후 신청자 직접 확인
                </div>
              </div>
            </div>
          </div>
        )}
      </section>

      <section className={styles.table_all_container}>
        <h3 className={styles.subheading}>제품사진</h3>
        <div className={styles.tableDiv}>
          <table className={styles.tableMemorial}>
            <thead>
              <th></th>
              <th>개인 위패 (사각 크리스탈)</th>
              <th>개인 위패 (원형 크리스탈)</th>
            </thead>
            <tbody>
              <tr>
                <td
                  className={styles.textStyle}
                  width={isMobile ? "162px" : "30%"}
                >
                  {" "}
                  예시 사진{" "}
                </td>
                <td>
                  {" "}
                  <img
                    src={square1}
                    width={"74px"}
                    height={"210px"}
                    alt="개인위패1"
                  />{" "}
                </td>
                <td>
                  {" "}
                  <img
                    src={circle1}
                    width={"69px"}
                    height={"210px"}
                    alt="개인위패2"
                  />{" "}
                </td>
              </tr>
              <tr>
                <td className={styles.textStyle}> 입력사항 </td>
                <td> 이름, 출생일, 사망일, 가족사항 </td>
                <td> 이름, 출생일, 사망일, 가족사항 </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className={styles.tableDiv}>
          <table className={styles.tableMemorial}>
            <thead>
              <th></th>
              <th>사진 위패 (사각 크리스탈)</th>
              <th> 위패 (원형 크리스탈)</th>
            </thead>
            <tbody>
              <tr>
                <td className={styles.textStyle}> 예시 사진 </td>
                <td>
                  {" "}
                  <img
                    src={square2}
                    width={"122.33px"}
                    height={"210px"}
                    alt="사진위패1"
                  />{" "}
                </td>
                <td>
                  {" "}
                  <img
                    src={circle2}
                    width={"105.68px"}
                    height={"210px"}
                    alt="사진위패2"
                  />{" "}
                </td>
              </tr>
              <tr>
                <td className={styles.textStyle}> 입력사항 </td>
                <td> 사진 최대 5장, 20자 내외 문구 </td>
                <td> 사진 최대 3장, 20자 내외 문구 </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className={styles.tableDiv2}>
          <table className={styles.signPost}>
            <thead>
              <th></th>
              <th colSpan={3}>표지석 (자연장지)</th>
            </thead>
            <tbody>
              <tr>
                <td height={"110px"} className={styles.textStyle}>
                  예시 사진
                </td>
                <td colSpan={3}>
                  <img
                    src={name}
                    width={"116px"}
                    height={"52px"}
                    alt="개인위패1"
                    vertical-align={"middle"}
                  />
                </td>
              </tr>
              <tr>
                <td height={"50px"} className={styles.textStyle}>
                  입력사항
                </td>
                <td colSpan={3}> 이름 </td>
              </tr>
              <tr>
                <td height={"210px"} className={styles.textStyle}>
                  예시 사진
                </td>
                <td width={"261px"}>
                  <img src={image1}></img>
                </td>
                <td width={"261px"}>
                  <img src={image2}></img>
                </td>
                <td width={"261px"}>
                  <img src={image3}></img>
                </td>
              </tr>
              <tr>
                <td height={"50px"} className={styles.textStyle}>
                  입력사항
                </td>
                <td colSpan={3}>이름, 출생일, 사망일</td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>

      <section className={styles.table_all_container}>
        <h3 className={styles.subheading2}>위패규격</h3>

        <div className={styles.tableDiv3}>
          <table className={styles.tabletSize}>
            <thead>
              <th></th>
              <th>정면</th>
              <th>측면</th>
              <th>후면</th>
            </thead>
            <tbody>
              <tr>
                <td height={"210px"} className={styles.textStyle}>
                  {" "}
                  예시 사진{" "}
                </td>
                <td>
                  {" "}
                  <img
                    src={plaquefront}
                    width={"110px"}
                    height={"180px"}
                    alt="정면"
                  />{" "}
                </td>
                <td>
                  {" "}
                  <img
                    src={plaqueside}
                    width={"110px"}
                    height={"180px"}
                    alt="측면"
                  />{" "}
                </td>
                <td>
                  {" "}
                  <img
                    src={plaqueback}
                    width={"110px"}
                    height={"180px"}
                    alt="후면"
                  />{" "}
                </td>
              </tr>
              <tr>
                <td height={"96px"} className={styles.textStyle}>
                  {" "}
                  위패 제작 <br />
                  권장크기{" "}
                </td>
                <td colSpan={3} className={styles.description}>
                  <ul>
                    <li>
                      가로 10cm, 세로 15cm, 변질 및 변형이 없는 재질(석재,
                      크리스탈, 플라스틱 등 )가능
                    </li>
                    <li className={styles.red}>
                      권장하는 규격을 초과하여 제작 시 봉안함의 각인이 위패에
                      가려질 수 있습니다.
                    </li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>

      <section className={styles.impossible_table}>
        <h3 className={styles.subheading}>봉안 불가 사례</h3>
        <div className={styles.tableDiv4}>
          <table>
            <tbody>
              <tr>
                <td>
                  {" "}
                  <img
                    src={impossible1}
                    width="100px"
                    height="85px"
                    alt="1"
                  />{" "}
                </td>
                <td>
                  {" "}
                  <img
                    src={impossible2}
                    width="100px"
                    height="85px"
                    alt="2"
                  />{" "}
                </td>
                <td>
                  {" "}
                  <img
                    src={impossible3}
                    width="100px"
                    height="85px"
                    alt="3"
                  />{" "}
                </td>
                <td>
                  {" "}
                  <img
                    src={impossible4}
                    width="100px"
                    height="85px"
                    alt="4"
                  />{" "}
                </td>
                <td>
                  {" "}
                  <img
                    src={impossible5}
                    width="100px"
                    height="85px"
                    alt="5"
                  />{" "}
                </td>
                <td>
                  {" "}
                  <img
                    src={impossible6}
                    width="100px"
                    height="85px"
                    alt="6"
                  />{" "}
                </td>
              </tr>
              <tr>
                <td>거치가 불가능한 제품</td>
                <td>규격을 초과하는 제품</td>
                <td>
                  목재 및 종이로 제작된
                  <br />
                  위패
                </td>
                <td>
                  미니어쳐 및 조각상 등<br />
                  부장품
                </td>
                <td>
                  사진 또는 사진교체
                  <br />
                  가능한 액자
                </td>
                <td>
                  봉안당 꽃장식(리스) 및<br />
                  부착물 등
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>
    </main>
  );
};

export default Inscription;
