import styles from "./Greeting.module.scss";
import background from "./background.svg";

export default function Greeting() {
    return (
        <div className={styles.greetingContainer}>
            <div className={styles.container}>
                <div className={styles.imageContainer}>
                    <img src={background} alt="배경 이미지" className={styles.image} />
                    <div className={styles.textWrapper}>
                        <div className={styles.textOverlay}>
                            <p>삶의 마지막 여정을 함께 하는 것은 무엇보다도 중요한 일입니다.<br></br></p> 
                            <p>저희 장례식장은 고인의 존엄과 유가족의 마음을 최우선으로 생각하며, 정성을 다해 모시는 것을 약속 드립니다.<br></br></p>
                            <p>저희는 여러분의 슬픔을 함께 나누고, 위로하며, 고인의 평안한 안식을 위해 최선을 다 할 것입니다.<br></br></p>
                            <p>저희 장례식장은 오랜 경험과 전문성을 바탕으로, 품격 있는 장례 서비스를 제공하고 있습니다.<br></br></p>
                            <p>고인의 마지막 길이 아름답고 존엄하게 마무리될 수 있도록, 모든 직원들이 한 마음으로 최선을 다하고 있습니다.<br></br></p>
                            <p>또한, 저희 장례식장은 고객의 편의를 위해 다양한 서비스를 제공하고 있으며, <br></br></p>
                            <p>언제든지 문의사항이나 도움이 필요 하실 때 신속하고 친절하게 응대할 준비가 되어 있습니다.<br></br></p>
                            <p>저희는 가족 같은 마음으로 여러분을 맞이하고, 끝까지 함께 할 것입니다.<br></br></p>
                            <p>슬픔의 순간에도 따뜻한 배려와 정성을 다해 모실 것을 약속 드리며, <br></br></p>
                            <p>저희 장례식장을 믿고 찾아주시는 모든 분들께 다시 한 번 깊은 감사의 인사를 드립니다.<br></br></p>
                            <p>감사합니다.</p>
                        </div>
                        <div className={styles.ownerOverlay}>
                                <p>울산하늘공원 장례식장(주)</p>
                                <p>대표이사&nbsp;&nbsp;&nbsp;노&nbsp;&nbsp;&nbsp;진&nbsp;&nbsp;&nbsp;한
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
