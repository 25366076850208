import React from "react";
import Slider from "react-slick";
import RoomInfoCard from "components/RoomInfoCard/RoomInfoCard";
import Information from "components/information/Information";
import FacilityCarousel from "components/facility/FacilityCarousel";
import MainImage from "components/MainImage/MainImage";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from "./MainPage.module.scss";

const MainPage: React.FC = () => {
  const today = new Date();
  const week = ["일", "월", "화", "수", "목", "금", "토"];
  const formattedDate = `${today.getFullYear()}. ${
    today.getMonth() + 1
  }. ${today.getDate()}.(${
    week[today.getDay()]
  })  ${today.getHours()}시 ${today.getMinutes()}분`;

  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    arrows: true,
    slidesToShow: 3,
    slidesToScroll: 3,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: false,
          arrows: false,
        },
      },
    ],
  };

  return (
    <div className={styles.container}>
      <MainImage />
      <div className={styles.roomInfoCardBackground}>
        <div className={styles.roomInfoCardSlide}>
          <div className={styles.timeTitle}>현재 {formattedDate}</div>
          <div className={styles.mainTitle}>
            울산하늘공원 장례식장 고인 현황
          </div>
          <div className={styles.sliderContainer}>
            <Slider {...settings}>
              <div className={styles.slideItem}>
                <RoomInfoCard
                  deceasedName="김철수"
                  roomNumber={101}
                  chiefMourner={["상 주: 김상철", "자 부: 김상순, 김복자"]}
                  burialSite="명복공원(2)"
                  casketingTime="2024-05-16 10:00"
                  funeralTime="2024-05-17 11:30"
                />
              </div>
              <div className={styles.slideItem}>
                <RoomInfoCard
                  deceasedName="홍길동"
                  roomNumber={202}
                  chiefMourner={[
                    "상 주: 홍수역",
                    "자 부: 홍만자, 홍은영, 홍가임",
                    "사 위: 김주만",
                    "손: 박은숙, 박",
                  ]}
                  burialSite="명복공원(3)"
                  casketingTime="2024-05-16 10:00"
                  funeralTime="2024-05-17 11:30"
                />
              </div>
              <div className={styles.slideItem}>
                <RoomInfoCard />
              </div>
              <div className={styles.slideItem}>
                <RoomInfoCard />
              </div>
              <div className={styles.slideItem}>
                <RoomInfoCard />
              </div>
              <div className={styles.slideItem}>
                <RoomInfoCard />
              </div>
            </Slider>
          </div>
        </div>
      </div>
      <div className={styles.facility}>
        <div className={styles.subTitle}>시설 현황 바로 가기</div>
        <div className={styles.mainTitle}>울산하늘공원 장례식장 시설 현황</div>
        <FacilityCarousel />
      </div>
      <div className={styles.informationBackground}>
        <div className={styles.information}>
          <div className={styles.subTitle}>건전한 장례문화를 선도하는</div>
          <div className={styles.mainTitle}>
            울산하늘공원 장례식장 이용 안내
          </div>
          <Information />
        </div>
      </div>
    </div>
  );
};

export default MainPage;
