import img1 from "./imgs/1.png";
import img2 from "./imgs/2.png";
import styles from "../Facilities.module.scss";

const Page = () => {
  return (
    <>
      <table className={styles.images}>
        <tbody>
          <tr>
            <td>
              <img src={img1} />
            </td>
            <td>
              <img src={img2} />
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default Page;
