import style from "./FuneralHallPage.module.scss";
import rightArrow from "../../../assets/imgs/RightArrow.svg";
import flower1 from "./imgs/flower1.png";
import flower2 from "./imgs/flower2.png";

const Page = () => {
  return (
    <div className={style.container}>
      <div className={style.callout}>24시간 연중 무휴</div>
      <h2 className={style.header}>빈소 이용안내</h2>
      <div className={style.parnassus}>
        <div>
          <h3 className={style.subheading}>운영안내</h3>
          <ul>
            <li>
              운구하시기 전에 반드시 빈소사용 가능 여부를 확인하여 주시기
              바랍니다.
            </li>
            <li>전문 장례지도사가 24시간 대기하고 있습니다.</li>
            <li style={{ color: "red" }}>
              (TEL: 052-254-9702, FAX: 052-263-0040)
            </li>
          </ul>
        </div>
        <div>
          <h3 className={style.subheading}>사용대상</h3>
          <ul>
            <li>장례식장은 누구든지 사용할 수 있습니다.</li>
          </ul>
        </div>
        <div>
          <h3 className={style.subheading}>사용료</h3>
          <section className={style.table_container}>
            <table className={style.table}>
              <thead>
                <tr>
                  <th style={{ width: 105 }}></th>
                  <th style={{ width: 230 }}>단위</th>
                  <th style={{ width: 230 }}>관내주민 / 양산주민</th>
                  <th style={{ width: 170 }}>단위</th>
                  <th style={{ width: 210 }}>비고</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>영결식장</td>
                  <td>1실당(1시간기준)</td>
                  <td>30,000</td>
                  <td>60,000</td>
                  <td></td>
                </tr>
                <tr>
                  <td rowSpan={2}>빈소</td>
                  <td>1실당(148㎡, 24시간기준)</td>
                  <td>112,000</td>
                  <td>224,000</td>
                  <td rowSpan={2}>추가 사용 시 요금 각각 적용</td>
                </tr>
                <tr>
                  <td style={{ fontFamily: "Pretendard-Regular" }}>
                    1실당(181㎡, 24시간기준)
                  </td>
                  <td>137,000</td>
                  <td>274,000</td>
                </tr>
                <tr>
                  <td>안치실</td>
                  <td>1구당(24시간 기준)</td>
                  <td>40,000</td>
                  <td>80,000</td>
                  <td></td>
                </tr>
                <tr>
                  <td>염습실</td>
                  <td>1회</td>
                  <td>30,000</td>
                  <td>60,000</td>
                  <td></td>
                </tr>
                <tr>
                  <td>가족휴게실</td>
                  <td>1실당(24시간 기준)</td>
                  <td>30,000</td>
                  <td>60,000</td>
                  <td></td>
                </tr>
              </tbody>
            </table>
            <span className={style.caution}>
              ※ 관내주민이란 사망 당시에 주민등록이 울산광역시에 되어 있는 자를
              말합니다.
            </span>
          </section>
        </div>
        <div>
          <h3 className={style.subheading}>사용료 감면 대상자</h3>
          <ul>
            <li>「국민기초생활보장법」에 따른 생계·의료급여 수급자</li>
            <li>「국가보훈기본법」에 따른 희생/공헌자와 그 배우자</li>
            <li>
              「시 관내에서 사망한 무연고자, 「사회복지사업법」에 따른
              사회복지시설 입소자 중 무연고자
            </li>
          </ul>
        </div>
        <div>
          <h3 className={style.subheading}>구비 서류</h3>
          <section className={style.table_container}>
            <table className={style.table}>
              <thead>
                <tr>
                  <th style={{ width: "125px" }}></th>
                  <th style={{ width: "315px" }}>방문하여 작성할 서류</th>
                  <th style={{ width: "210px" }}>사용자 필수 지참 서류</th>
                  <th style={{ width: "295px" }}>
                    울산하늘공원 확인 가능 서류
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>병사</td>
                  <td>울산하늘공원 사용 허가 신청서</td>
                  <td>사망진단서</td>
                  <td>
                    <ul>
                      <li>주민등록등본(고인)</li>
                      <li>국가유공자증명</li>
                      <li>국민기초생활수급증명</li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td>외인사</td>
                  <td>울산하늘공원 사용 허가 신청서</td>
                  <td>
                    <ul>
                      <li>시체 검안서</li>
                      <li>검사 지휘서</li>
                    </ul>
                  </td>
                  <td>
                    <ul>
                      <li>주민등록등본(고인)</li>
                      <li>국가유공자증명</li>
                      <li>국민기초생활수급증명</li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
            <span className={style.caution} style={{ color: "#FF0000" }}>
              ※ 시설사용 신청인은 신분증을 지참하셔야 합니다.
            </span>
          </section>
        </div>
      </div>
      <h2 className={style.header}>근조화 이용안내</h2>
      <div className={style.parnassus}>
        <div>
          <h3 className={style.subheading}>신근조화 도입배경</h3>
          <p style={{ marginBottom: "5px" }}>
            울산하늘공원은 공공기관으로써 장례식장의 환경을 개선하고,
          </p>
          <p style={{ marginBottom: 0 }}>
            우리나라의 장례 문화 선진화에 선도적 역할을 담당하고자 기존의 3단
            근조화를 대신하여 친환경 신근조화를 도입합니다.
          </p>
        </div>
      </div>
      <div className={style.versus}>
        <div className={style.column}>
          <div className={style.box} style={{ height: "241px" }}>
            <p>현재의 장례식장 근조화는</p>
            <p>다른 상가(喪家)에 재사용하는 폐단과 크기가 크고,</p>
            <p>플라스틱 및 목재 등의 부품을 사용해</p>
            <p>폐기처분이 어려울뿐만 아니라</p>
            <p>환경을 오염시키는 등 부작용이 있습니다.</p>
          </div>
          <p>&lt;기존 근조화&gt;</p>
        </div>
        <div className={style.column}>
          <div className={style.arrow} />
        </div>
        <div className={style.column}>
          <div className={style.box} style={{ height: "106px" }}>
            <p>
              신근조화는 제작, 운반, 설치가 용이하며{" "}
              <br className={style.MBbr} />
              폐기물 감축으로 인한 <br className={style.MBbr} />
              <br className={style.PCbr} />
              <span style={{ color: "#4095A2" }}>
                자원낭비 및 환경오염 예방
              </span>
              에 도움이 됩니다.
              <br className={style.PCbr} />
            </p>
          </div>
          <div className={style.row} style={{ height: "30px" }}>
            <div></div>
            <div></div>
          </div>
          <div className={style.box} style={{ height: "106px" }}>
            <p>
              신근조화는{" "}
              <span style={{ color: "#4095A2" }}>
                체면치례와 허례허식 보다는 <br className={style.MBbr} />
                작지만 실용적
              </span>
              이고,
              <br className={style.PCbr} />
              정성을 담은 것으로 <br className={style.MBbr} />
              건전한 장례문화 정착에 기여하게 될 것입니다.
              <br className={style.PCbr} />
            </p>
          </div>
          <p>&lt;신근조화&gt;</p>
        </div>
      </div>
      <div className={style.parnassus}>
        <div>
          <h3 className={style.subheading}>형태 및 규격</h3>
          <ul>
            <li>울산하늘공원에서는 친환경 신근조화만 사용 가능합니다.</li>
            <li style={{ color: "#ff0000" }}>
              생화 사용을 원칙으로 하며, 표 규격 외에는 사용을 금합니다.
            </li>
          </ul>
          <section className={style.table_container}>
            <table className={style.table} style={{ marginTop: "20px" }}>
              <thead>
                <tr>
                  <th style={{ width: "162px" }}></th>
                  <th style={{ width: "402px" }}>계단형</th>
                  <th style={{ width: "381px" }}>스탠드형</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>예시사진</td>
                  <td>
                    <img src={flower1}></img>
                  </td>
                  <td>
                    <img src={flower2}></img>
                  </td>
                </tr>
                <tr>
                  <td>가로</td>
                  <td>100cm이내</td>
                  <td>25cm이내</td>
                </tr>
                <tr>
                  <td>세로</td>
                  <td>90cm이내(각층 30cm이내)</td>
                  <td>25cm이내</td>
                </tr>
                <tr>
                  <td>높이</td>
                  <td>120cm이내</td>
                  <td>120cm 이내</td>
                </tr>
              </tbody>
            </table>
          </section>
          <div className={style.callout} style={{ marginTop: "20px" }}>
            주문전화: 052)264-1017
          </div>
        </div>
      </div>
    </div>
  );
};

export default Page;
