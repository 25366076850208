import { useEffect } from "react";
import styles from "./Dicrections.module.scss";

declare global {
  interface Window {
    kakao: any;
  }
}

const DirectionPage = () => {
  useEffect(() => {
    let container = document.getElementById(`map`); // 지도를 담을 영역의 DOM 레퍼런스
    let options = {
      center: new window.kakao.maps.LatLng(
        35.470755896136545,
        129.13367039981992
      ), // 지도 중심 좌표
      level: 3, // 지도의 레벨(확대, 축소 정도)
    };

    let map = new window.kakao.maps.Map(container, options); // 지도 생성 및 객체 리턴
    const markerPosition = new window.kakao.maps.LatLng(
      35.470755896136545,
      129.13367039981992
    );

    // 마커를 생성합니다
    const marker = new window.kakao.maps.Marker({
      position: markerPosition,
    });

    // 마커가 지도 위에 표시되도록 설정합니다
    marker.setMap(map);
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.callout}>
        <p className={styles.highlight_darken}>울산하늘공원 장례식장 주소</p>
        <p>
          → 울산 울주군 삼동면 보삼길 550, <br />
          (지번) 울산 울주군 삼동면 조일리 1532
        </p>
      </div>
      <div>
        <h2 className={styles.subheading}>시내버스 이용시</h2>
        <div className={styles.content}>
          <a className={styles.redirection} href="https://its.ulsan.kr/">
            울산 교통관리센터 바로가기
          </a>
          <table className={styles.table}>
            <thead>
              <tr>
                <th colSpan={2}>
                  시내버스 <span className={styles.highlight}>952번</span>{" "}
                  시간표 (율리차고지 ↔ 울산하늘공원)
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>운행경로</td>
                <td>
                  하늘공원 - 보삼마을 - 조일입구 - 지랑 - 삼동면민운동장 -
                  내외양 - 금곡 - 사촌 - 삼동초등학교 - 출강 - 작동 -
                  한솔그린빌(웅촌) - 쌍용하나빌리지 - 우신고등학교 - 울산과학대
                  - 울산대학교 - 신복 - 율리차고지
                </td>
              </tr>
              <tr>
                <td>율리차고지 출발</td>
                <td>
                  <div className={styles.time}>
                    <span>06:00</span>
                    <span>08:40</span>
                    <span>11:30</span>
                    <span>14:20</span>
                    <span>17:20</span>
                  </div>
                </td>
              </tr>
              <tr>
                <td>하늘공원 출발</td>
                <td>
                  <div className={styles.time}>
                    <span>07:20</span>
                    <span>10:10</span>
                    <span>13:00</span>
                    <span>16:00</span>
                    <span>18:50</span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className={styles.subheading}>자가용 이용시</div>
        <div className={styles.content} style={{ paddingBottom: "20px" }}>
          <div className={styles.path}>
            <h3>고속도로</h3>
            <p>
              신복로터리 → 울산고속도로 진입 → 경부고속도로 → 통도사IC(출차) →
              군도34호(삼동방면) → 현대자동차출구사무소 → 조일주유소 →
              울산하늘공원
            </p>
          </div>
          <div className={styles.path}>
            <h3>언양국도</h3>
            <p>
              신복로터리 → 범서 → 반천 → 언양 → 삼성SDI → 통도사방면
              →군도34호(삼동방면) → 현대자동차출구사무소 → 조일주유소 →
              울산하늘공원
            </p>
          </div>
          <div className={styles.path}>
            <h3>삼동로</h3>
            <p>
              신복로터리 → 울산대학교 → 대복 → 삼동면사무소 → 조일주유소 →
              울산하늘공원
            </p>
          </div>
        </div>
        <div id="map" className={styles.map}></div>
      </div>
    </div>
  );
};

export default DirectionPage;
