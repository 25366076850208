import styles from "./AncestralRitesPage.module.scss";

const AncestralRitesPage = () => {
  return (
    <div className={styles.container}>
      <h2 className={styles.subheading}>제사의 종류</h2>
      <ul>
        <li>
          성복제
          <ul>
            <li>
              입관이 끝나면 남·녀 상주들은 정식으로 상복을 입고, 분향소에서
              제수를 올리고, 향을 피우며 첫 제사를 올리는 것입니다.
            </li>
            <li>주로 유교와 불교식에서 행합니다.</li>
            <li>
              최근에는 성복제를 올리기 전에 상복을 입고 있는 경향이 있습니다.
            </li>
          </ul>
        </li>
        <li>
          발인제
          <ul>
            <li>
              장지로 떠나기 전 빈소에서 제물을 준비해 지내기도 하며, 故人을
              영구버스(영구차)에 모셔 놓고 지내는 제사를 의미합니다.
            </li>
            <li>
              {" "}
              발인제 의미는『이제 故人 000님의 유택(무덤)으로 모시고자 하오니,
              저희들과는 이 세상에서 다시 뵙지 못하는 영원한 이별을 하오니,
              故人의 평안을 비는 마음』에서 지내는 제사입니다.
            </li>
          </ul>
        </li>
        <li>
          노제
          <ul>
            <li>
              장지로 가는 도중에 평소 故人이 애착이 있던 곳이나, 살았던 곳에
              들러 제사를 지내는 것을 말합니다.
            </li>
          </ul>
        </li>
        <li>
          산신제
          <ul>
            <li>묘의 광중을 파기 전에 올리는 제사를 말합니다.</li>
            <li>
              사토제 의미는 이 곳에 광중을 세우니 신(神)께서 보우하사 후한이
              없도록 지켜 주길 비는 마음에서 지내는 제사입니다.
            </li>
            <li>광중이란, 땅을 사각으로 파서 관을 모시는 자리입니다.</li>
          </ul>
        </li>
        <li>
          평토제
          <ul>
            <li>
              광중을 메우기 시작하여 평지와 높이가 같게 되면 지내는 제사입니다.
            </li>
            <li>평토제는 맏사위가 담당하는 것이 관례로 되어있습니다.</li>
          </ul>
        </li>
        <li>
          성분제
          <ul>
            <li>장지에서 봉분이 완료되면 지내는 제사입니다.</li>
            <li>
              성분제 의미는 무덤을 여기에 정하였으니 신께서 보우하사 후한이
              없도록 지켜 주길 비는 마음에서 지내는 제사입니다.
            </li>
          </ul>
        </li>
        <li>
          초우제
          <ul>
            <li>초우제는 산에서 돌아온 날 저녁에 지내는 제사입니다.</li>
            <li>
              장지가 멀어 다른 곳에서 숙박을 하더라도 그 곳에서 제를 지내는 것이
              원칙입니다.
            </li>
          </ul>
        </li>
        <li>
          재우제
          <ul>
            <li>재우제는 산에서 돌아온 다음날 식전에 지내는 제사입니다.</li>
            <li>그러나 요즘은 재우를 생략하는 경우가 많습니다.</li>
          </ul>
        </li>
        <li>
          삼우제
          <ul>
            <li>발인한 날로부터 이틀째 되는 날 지내는 제사입니다.</li>
            <li>
              우제란, 돌아가신 영혼을 위로하는 제사로 집에 돌아온 자손들이
              故人(망자)을 홀로 묘소에 모셔 놓게 되어 외롭고 놀라지 않을까
              걱정되어 예를 드리는 의식입니다.
            </li>
          </ul>
        </li>
        <li>
          49 제
          <ul>
            <li>임종하신 날부터 49일이 되는 날 지내는 제사입니다.</li>
            <li>
              49제 의미는 불교 행사로서 육체를 이탈한 영혼은 바로 극락으로 가지
              못하고 온갖 세파에서 저지른 죄악을 정화하는 기간이며, 어느 곳으로
              갈 것인지 정해지지 않아 심판의 결과를 기다리는 기간을 49일로
              보고있어 가족들은 영혼이 좋은 곳으로 가기를 기원하는 마음에서
              지내는 제사입니다.
            </li>
          </ul>
        </li>
        <li>
          기제
          <ul>
            <li>고인이 돌아가신 날 해마다 한 번씩 지내는 제사입니다</li>
            <li>지내는 시간은 자정에 지내는 것이 원칙입니다.</li>
          </ul>
        </li>
        <li>
          제사상 차림
          <ul>
            <li>
              제수의 진설법은 ‘가가례’라 하여 지방과 가문에 따라 조금씩 다르지만
              상차림의 기본원칙은 공통적이며, 관행적으로 지켜 오는 격식이
              있습니다.
            </li>
            <li>제사에는 영정 또는 신위가 놓인 곳을 북쪽으로 합니다.</li>
            <li>
              장소의 형편상 북쪽이 아닌 곳에 제사상을 차렸더라도 제사상이 있는
              곳을 북쪽으로 정합니다.
            </li>
            <li>
              영정, 신위 등을 북쪽에 모시는 이유는 귀신을 다스리는 신이 북쪽에
              있다고 믿었기 때문입니다.(남좌여우)
            </li>
            <li>
              남자조상의 신위,밥,국,술잔은 왼쪽에 놓고 여자조상은 오른쪽에
              놓습니다.
            </li>
            <li>남자조상은 서쪽, 여자조상은 동쪽에 위치합니다.</li>
            <li>
              합설: 밥,국,술잔,수저는 따로 놓아 나머지 제수는 공통으로 하고,
              조상의 제사는 배우자가 있을 경우 함께 모십니다.
            </li>
            <li>
              반서갱동: 산 사람에게 올리는 상차림과 반대를 의미하며, 수저는
              중앙에 놓고 밥은 서쪽 국은 동쪽에 위치하게 합니다.
            </li>
            <li>어동육서: 고기는 서쪽 생선이 동쪽에 위치합니다.</li>
            <li>두동미서</li>
            <li className={styles.indent}>
              꼬리는 서쪽 머리는 동쪽에 위치(적전중앙)
            </li>
            <li className={styles.indent}>적은 중앙에 위치(생동숙서)</li>
            <li className={styles.indent}>
              나물은 서쪽 김치는 동쪽에 위치(좌포우혜)
            </li>
            <li className={styles.indent}>
              포는 서쪽 식혜는 동쪽에 위치(조율이시)
            </li>
            <li className={styles.indent}>
              대추,밤,배,감순서로 놓습니다.(홍동백서)
            </li>
            <li className={styles.indent}>
              흰 과일은 서쪽 붉은 과일은 동쪽에 놓습니다.(신위를 기준으로 1열은
              밥과 국, 2열은 적과 전 3열은 탕, 4열은 포,나물 5열은 과일 및 과자)
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
};

export default AncestralRitesPage;
